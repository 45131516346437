// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as TemplateLAI$CTEM from "../Components/ModelTemplates/LAI/TemplateLAI.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";

function PresentationPage(Props) {
  var work = Props.work;
  var templateData = work.templateData;
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "presentation-container",
                  Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                Caml_option.some(templateData !== undefined && typeof templateData !== "number" ? React.createElement(TemplateLAI$CTEM.make, {
                                            work: work,
                                            workName: work.fileName,
                                            data: templateData._0
                                          }) : null),
                                undefined,
                                undefined
                              ]))),
                  undefined,
                  undefined
                ]));
}

var make = PresentationPage;

export {
  make ,
  
}
/* react Not a pure module */
