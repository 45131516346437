// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Utils$CTEM from "../../../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as LAICalculation$CTEM from "../LAICalculation.bs.js";
import * as StackedBarChart$CTEM from "../../StackedBarChart.bs.js";
import Grid from "@material-ui/core/Grid";

function CostBreakdown(Props) {
  var countryName = Props.countryName;
  var data = Props.data;
  var currencyCode = Props.currencyCode;
  var match = React.useReducer((function (_state, action) {
          return {
                  showDiff: action._0
                };
        }), {
        showDiff: true
      });
  var dispatch = match[1];
  var state = match[0];
  var drugCostOld = LAICalculation$CTEM.totalDrugCost(true, data);
  var drugCostNew = LAICalculation$CTEM.totalDrugCost(false, data);
  var hospitalizationCostOld = LAICalculation$CTEM.totalCostOfHospitalizationCurrent(data);
  var hospitalizationCostNew = LAICalculation$CTEM.totalCostOfHospitalizationNew(data);
  var indirectCostsOld = LAICalculation$CTEM.totalIndirectCostsCurrent(data);
  var indirectCostsNew = LAICalculation$CTEM.totalIndirectCostsNew(data);
  var adminAndPhysicianCostsOld = LAICalculation$CTEM.oralErAndPhysicianCostCurrent(data);
  var adminAndPhysicianCostsNew = LAICalculation$CTEM.totalCostErPhysician(data) + LAICalculation$CTEM.laiAdministrationCosts(data);
  var costsOld = drugCostOld + hospitalizationCostOld + indirectCostsOld + adminAndPhysicianCostsOld;
  var costsNew = drugCostNew + hospitalizationCostNew + indirectCostsNew + adminAndPhysicianCostsNew;
  var costOld = [
    {
      title: "Drug costs",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: drugCostOld.toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#EA7E21",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    },
    {
      title: "Hospitalization costs",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: hospitalizationCostOld.toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#2380B4",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    },
    {
      title: "Physician visits and Admin costs",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: adminAndPhysicianCostsOld.toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#6DBDEB",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    },
    {
      title: "Indirect costs",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: indirectCostsOld.toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#144F70",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }
  ];
  var costNew = [
    {
      title: "Drug costs",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: drugCostNew.toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#EA7E21",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    },
    {
      title: "Hospitalization costs",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: hospitalizationCostNew.toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#2380B4",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    },
    {
      title: "Physician visits and Admin costs",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: adminAndPhysicianCostsNew.toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#6DBDEB",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    },
    {
      title: "Indirect costs",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: indirectCostsNew.toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#144F70",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }
  ];
  var costBreakdownOld = {
    topic: "",
    subTitle: "",
    oldData: costOld,
    newData: costNew,
    unitName: "",
    tooltipElement: undefined,
    icons: /* [] */0
  };
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "lai-cost-breakdown",
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "text-left",
                      Caml_option.some(React.createElement("h3", undefined, countryName)),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "text-center",
                      Caml_option.some(React.createElement("h3", undefined, "Cost comparison")),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "overall-table",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "overall-header",
                                        Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      "",
                                                      Caml_option.some(null),
                                                      undefined,
                                                      undefined
                                                    ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                          undefined,
                                                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          "Patients per year",
                                                          undefined,
                                                          undefined
                                                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                          undefined,
                                                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          "text-right",
                                                          Caml_option.some(Caml_array.get(Utils$CTEM.getNumberFormat(data.targetedSchizophrenia.value).split("."), 0)),
                                                          undefined,
                                                          undefined
                                                        ])))),
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "overall-content",
                                        Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      Caml_option.some(null),
                                                      undefined,
                                                      undefined
                                                    ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                          undefined,
                                                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          Caml_option.some(React.createElement("b", undefined, "Without LAIs")),
                                                          undefined,
                                                          undefined
                                                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                          undefined,
                                                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          "line-bottom",
                                                          "Total economic impact",
                                                          undefined,
                                                          undefined
                                                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                          undefined,
                                                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          "text-right line-bottom",
                                                          Caml_option.some(currencyCode + (" " + Utils$CTEM.getNumberFormat(costsOld.toString()))),
                                                          undefined,
                                                          undefined
                                                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                          undefined,
                                                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          Caml_option.some(React.createElement("b", undefined, "With LAIs")),
                                                          undefined,
                                                          undefined
                                                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                          undefined,
                                                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        "patients-data",
                                                                        Caml_option.some(null),
                                                                        undefined,
                                                                        undefined
                                                                      ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                            undefined,
                                                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined
                                                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                            undefined,
                                                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            "Product usage",
                                                                            undefined,
                                                                            undefined
                                                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                            undefined,
                                                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            "text-right",
                                                                            "Patient",
                                                                            undefined,
                                                                            undefined
                                                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                            undefined,
                                                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            "Oral",
                                                                            undefined,
                                                                            undefined
                                                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                            undefined,
                                                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            Caml_option.some(React.createElement("div", {
                                                                                      style: {
                                                                                        background: "#009bf5",
                                                                                        height: "100%",
                                                                                        width: data.marketShare.oral.value.toString() + "%"
                                                                                      }
                                                                                    })),
                                                                            undefined,
                                                                            undefined
                                                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                            undefined,
                                                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            "text-right",
                                                                            Caml_option.some(Caml_array.get(Utils$CTEM.getNumberFormat(data.targetedSchizophrenia.value * data.marketShare.oral.value / 100).split("."), 0)),
                                                                            undefined,
                                                                            undefined
                                                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                            undefined,
                                                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            "LAI",
                                                                            undefined,
                                                                            undefined
                                                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                            undefined,
                                                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            Caml_option.some(React.createElement("div", {
                                                                                      style: {
                                                                                        background: "#ff5400",
                                                                                        height: "100%",
                                                                                        width: data.marketShare.lai.value.toString() + "%"
                                                                                      }
                                                                                    })),
                                                                            undefined,
                                                                            undefined
                                                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                            undefined,
                                                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            "text-right",
                                                                            Caml_option.some(Caml_array.get(Utils$CTEM.getNumberFormat(data.targetedSchizophrenia.value * data.marketShare.lai.value / 100).split("."), 0)),
                                                                            undefined,
                                                                            undefined
                                                                          ])))),
                                                          undefined,
                                                          undefined
                                                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                          undefined,
                                                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          "Total economic impact",
                                                          undefined,
                                                          undefined
                                                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                          undefined,
                                                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          "text-right",
                                                          Caml_option.some(currencyCode + (" " + Utils$CTEM.getNumberFormat(costsNew.toString()))),
                                                          undefined,
                                                          undefined
                                                        ])))),
                                        undefined,
                                        undefined
                                      ])))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "cost-breakdown-col",
                      Caml_option.some(React.createElement("div", {
                                style: {
                                  height: "90%",
                                  width: "100%"
                                }
                              }, React.createElement(StackedBarChart$CTEM.make, {
                                    verticalChartData: costBreakdownOld,
                                    className: " single-bar-chart",
                                    layout: "vertical",
                                    colorBy: "id",
                                    enableLabel: true,
                                    enableGridY: true,
                                    unitGridYValue: currencyCode,
                                    showLegends: /* VisibleWith */{
                                      _0: state.showDiff
                                    },
                                    onlyDifference: true,
                                    height: "85%",
                                    padding: 0.2,
                                    margin: {
                                      top: 10,
                                      right: 250,
                                      bottom: 70,
                                      left: 30
                                    },
                                    onClick: (function (param) {
                                        return Curry._1(dispatch, /* UpdateText */{
                                                    _0: !state.showDiff
                                                  });
                                      })
                                  }))),
                      undefined,
                      undefined
                    ])));
}

var make = CostBreakdown;

export {
  make ,
  
}
/* react Not a pure module */
