// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";

function drugCostPerDay(dailyDose, costPerUnit) {
  return dailyDose * costPerUnit;
}

function drugCostPerYear(dailyDose, costPerUnit) {
  return dailyDose * costPerUnit * 365;
}

function patientsIndirectCostPerMonth(data) {
  return data.unemploymentRate.value / 100 * data.minimumDailyWage.value * data.workingDaysPerMonth.value + (1.0 - data.unemploymentRate.value / 100) * data.minimumDailyWage.value * (data.absentHours.value / 8);
}

function patientsIndirectCostLai(data) {
  return data.unemploymentRate.value / 100 * (data.improvementRateWithLAIs.value / 100) * data.minimumDailyWage.value * data.workingDaysPerMonth.value + (1 - data.unemploymentRate.value / 100 * (data.improvementRateWithLAIs.value / 100)) * data.minimumDailyWage.value * (data.absentHours.value / 8);
}

function caregiversIndirectCostPerMonth(data) {
  return data.indirectCostCaregiver.workHour.value / 8 * data.indirectCostPatient.minimumDailyWage.value;
}

function caregiversIndirectCostLai(data) {
  return data.indirectCostCaregiver.workHour.value * ((1 - data.indirectCostCaregiver.improvementInProductivity.value / 100) / 8) * data.indirectCostPatient.minimumDailyWage.value;
}

function oralHospitalizedCurrent(data) {
  return data.targetedSchizophrenia.value * (data.overallHealthcare.oral.hospitalizationRate.value / 100);
}

function oralHospitalizedNew(data) {
  return data.targetedSchizophrenia.value * (1 - data.marketShare.lai.value / 100) * (data.overallHealthcare.oral.hospitalizationRate.value / 100);
}

function oralRelapsedCurrent(data) {
  return data.targetedSchizophrenia.value * (data.overallHealthcare.oral.avgRelapseRate.value / 100);
}

function oralRelapsedNew(data) {
  return data.targetedSchizophrenia.value * (1 - data.marketShare.lai.value / 100) * (data.overallHealthcare.oral.avgRelapseRate.value / 100);
}

function laiHospitalized(data) {
  return data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) * (data.overallHealthcare.oral.hospitalizationRate.value / 100) * (1 - data.overallHealthcare.lais.hospitalizationRate.value / 100);
}

function laiRelapsed(data) {
  return data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) * (data.overallHealthcare.oral.avgRelapseRate.value / 100) * (1 - data.overallHealthcare.lais.avgRelapseRate.value / 100);
}

function oralHospAndRelapsedCurrent(data) {
  return oralHospitalizedCurrent(data) * (data.overallHealthcare.oral.relapseRate.value / 100);
}

function oralHospAndRelapsedNew(data) {
  return oralHospitalizedNew(data) * (data.overallHealthcare.oral.relapseRate.value / 100);
}

function oralRelapsedNotHospCurrent(data) {
  return oralRelapsedCurrent(data) - oralHospAndRelapsedCurrent(data);
}

function oralRelapsedNotHospNew(data) {
  return oralRelapsedNew(data) - oralHospAndRelapsedNew(data);
}

function oralRelapseOtherReasonsCurrent(data) {
  return oralHospitalizedCurrent(data) - oralHospAndRelapsedCurrent(data);
}

function oralRelapseOtherReasonsNew(data) {
  return oralHospitalizedNew(data) - oralHospAndRelapsedNew(data);
}

function oralNotHospNorRelapsedCurrent(data) {
  return data.targetedSchizophrenia.value - oralRelapsedNotHospCurrent(data) - oralHospAndRelapsedCurrent(data) - oralRelapseOtherReasonsCurrent(data);
}

function oralNotHospNorRelapsedNew(data) {
  return data.targetedSchizophrenia.value * (1 - data.marketShare.lai.value / 100) - oralRelapsedNotHospNew(data) - oralHospAndRelapsedNew(data) - oralRelapseOtherReasonsNew(data);
}

function totalOralPopulationCurrent(data) {
  return oralNotHospNorRelapsedCurrent(data) + oralRelapsedNotHospCurrent(data) + oralHospAndRelapsedCurrent(data) + oralRelapseOtherReasonsCurrent(data);
}

function totalOralPopulationNew(data) {
  return oralNotHospNorRelapsedNew(data) + oralRelapsedNotHospNew(data) + oralHospAndRelapsedNew(data) + oralRelapseOtherReasonsNew(data);
}

function laiHospAndRelapsed(data) {
  return laiHospitalized(data) * (data.overallHealthcare.oral.relapseRate.value / 100);
}

function laiRelapsedNotHosp(data) {
  return laiRelapsed(data) - laiHospAndRelapsed(data);
}

function laiRelapseOtherReasons(data) {
  return laiHospitalized(data) - laiHospAndRelapsed(data);
}

function laiNotHospNorRelapsed(data) {
  return data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) - laiRelapsedNotHosp(data) - laiHospAndRelapsed(data) - laiRelapseOtherReasons(data);
}

function totalLAIPopulation(data) {
  return laiNotHospNorRelapsed(data) + laiRelapsedNotHosp(data) + laiHospAndRelapsed(data) + laiRelapseOtherReasons(data);
}

function totalPopulationCurrent(data) {
  return totalOralPopulationCurrent(data) + totalLAIPopulation(data);
}

function totalPopulationNew(data) {
  return totalOralPopulationNew(data) + totalLAIPopulation(data);
}

function oralHospDaysFromHospAndRelapsedCurrent(data) {
  return oralHospAndRelapsedCurrent(data) * data.overallHealthcare.oral.lengthOfStay.value;
}

function oralHospDaysFromHospAndRelapsedNew(data) {
  return oralHospAndRelapsedNew(data) * data.overallHealthcare.oral.lengthOfStay.value;
}

function oralHospDaysFromRelapseOtherReasonsCurrent(data) {
  return oralRelapseOtherReasonsCurrent(data) * data.overallHealthcare.oral.lengthOfStay.value;
}

function oralHospDaysFromRelapseOtherReasonsNew(data) {
  return oralRelapseOtherReasonsNew(data) * data.overallHealthcare.oral.lengthOfStay.value;
}

function laiHospDaysFromHospAndRelapsed(data) {
  return laiHospAndRelapsed(data) * data.overallHealthcare.oral.lengthOfStay.value * (1 - data.overallHealthcare.lais.lengthOfStay.value / 100);
}

function laiHospDaysFromRelapseOtherReasons(data) {
  return laiRelapseOtherReasons(data) * data.overallHealthcare.oral.lengthOfStay.value * (1 - data.overallHealthcare.lais.lengthOfStay.value / 100);
}

function totalHospitalizedDaysCurrent(data) {
  return oralHospDaysFromHospAndRelapsedCurrent(data) + oralHospDaysFromRelapseOtherReasonsCurrent(data);
}

function totalHospitalizedDaysNew(data) {
  return oralHospDaysFromHospAndRelapsedNew(data) + oralHospDaysFromRelapseOtherReasonsNew(data) + (laiHospDaysFromHospAndRelapsed(data) + laiHospDaysFromRelapseOtherReasons(data));
}

function annualChangeHospDays(data) {
  return totalHospitalizedDaysNew(data) - totalHospitalizedDaysCurrent(data);
}

function oralIncarcerationCaseCurrent(data) {
  return totalOralPopulationCurrent(data) * (data.indirectCosts.incarceration.oralPatients.value / 100);
}

function oralIncarcerationCaseNew(data) {
  return totalOralPopulationNew(data) * (data.indirectCosts.incarceration.oralPatients.value / 100);
}

function laiIncarcerationCase(data) {
  return totalLAIPopulation(data) * (data.indirectCosts.incarceration.pp1mPatients.value / 100);
}

function totalIncarcerationCaseNew(data) {
  return oralIncarcerationCaseNew(data) + laiIncarcerationCase(data);
}

function annualChangeInCriminalCase(data) {
  return totalIncarcerationCaseNew(data) - oralIncarcerationCaseCurrent(data);
}

function drugCostFromDrugId(isCurrent, drugId, data) {
  var drug = List.find_opt((function (drug) {
          if (isCurrent) {
            if (drug.id === drugId) {
              return drug.drugType === /* OralDrug */0;
            } else {
              return false;
            }
          } else {
            return drug.id === drugId;
          }
        }), data.drugs);
  if (drug === undefined) {
    return 0;
  }
  if (isCurrent) {
    return data.targetedSchizophrenia.value * (drug.marketShare.withoutLAI.value / 100) * drugCostPerYear(drug.dailyDose.value, drug.costPerUnit.value);
  }
  var timePerYear = drug.drugType;
  if (timePerYear) {
    return data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) * (data.marketShare.pp1m.value / 100) * (drug.dailyDose.value * drug.costPerUnit.value * timePerYear._0);
  } else {
    return data.targetedSchizophrenia.value * (1 - data.marketShare.lai.value / 100) * (drug.marketShare.withoutLAI.value / 100 - drug.marketShare.withoutLAI.value / 100 * (data.marketShare.lai.value / 100)) * drugCostPerYear(drug.dailyDose.value, drug.costPerUnit.value);
  }
}

function totalDrugCost(isCurrent, data) {
  var total = [0];
  List.map((function (drug) {
          return Caml_array.set(total, 0, Caml_array.get(total, 0) + drugCostFromDrugId(isCurrent, drug.id, data));
        }), data.drugs);
  return Caml_array.get(total, 0);
}

function annualChangeInDrugCosts(data) {
  return totalDrugCost(false, data) - totalDrugCost(true, data);
}

function totalCostOfHospitalizationCurrent(data) {
  return totalHospitalizedDaysCurrent(data) * data.indirectCosts.otherMedicalCost.hospitalizationCost.value;
}

function totalCostOfHospitalizationNew(data) {
  return totalHospitalizedDaysNew(data) * data.indirectCosts.otherMedicalCost.hospitalizationCost.value;
}

function changeInHospitalizationCosts(data) {
  return totalCostOfHospitalizationNew(data) - totalCostOfHospitalizationNew(data);
}

function oralErAndPhysicianCostCurrent(data) {
  return data.targetedSchizophrenia.value * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.value * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.value;
}

function oralErAndPhysicianCostNew(data) {
  return data.targetedSchizophrenia.value * (1 - data.marketShare.lai.value / 100) * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.value * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.value;
}

function laiErAndPhysicianCost(data) {
  return data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) * (data.marketShare.pp1m.value / 100) * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.value * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.value + data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) * (1 - data.marketShare.pp1m.value / 100) * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M.value * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.value;
}

function totalCostErPhysician(data) {
  return oralErAndPhysicianCostNew(data) + laiErAndPhysicianCost(data);
}

function changeInErPhysician(data) {
  return totalCostErPhysician(data) - oralErAndPhysicianCostCurrent(data);
}

function laiAdministrationCosts(data) {
  return data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) * (data.marketShare.pp1m.value / 100) * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.value * data.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI.value + data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) * (1 - data.marketShare.pp1m.value / 100) * data.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M.value * data.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI.value;
}

function indirectCostsPatientOralCurrent(data) {
  return data.targetedSchizophrenia.value * patientsIndirectCostPerMonth(data.indirectCosts.indirectCostPatient) * 12;
}

function indirectCostsPatientOralNew(data) {
  return data.targetedSchizophrenia.value * (1 - data.marketShare.lai.value / 100) * patientsIndirectCostPerMonth(data.indirectCosts.indirectCostPatient) * 12;
}

function indirectCostsPatientLai(data) {
  return data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) * patientsIndirectCostLai(data.indirectCosts.indirectCostPatient) * 12;
}

function indirectCostsCaregiversOralCurrent(data) {
  return data.targetedSchizophrenia.value * caregiversIndirectCostPerMonth(data.indirectCosts) * 12;
}

function indirectCostsCaregiversOralNew(data) {
  return data.targetedSchizophrenia.value * (1 - data.marketShare.lai.value / 100) * caregiversIndirectCostPerMonth(data.indirectCosts) * 12;
}

function indirectCostsCaregiversLai(data) {
  return data.targetedSchizophrenia.value * (data.marketShare.lai.value / 100) * caregiversIndirectCostLai(data.indirectCosts) * 12;
}

function totalIndirectCostsCurrent(data) {
  return indirectCostsPatientOralCurrent(data) + indirectCostsCaregiversOralCurrent(data);
}

function totalIndirectCostsNew(data) {
  return indirectCostsPatientOralNew(data) + indirectCostsPatientLai(data) + indirectCostsCaregiversOralNew(data) + indirectCostsCaregiversLai(data);
}

function changeInIndirectCosts(data) {
  return totalIndirectCostsNew(data) - totalIndirectCostsCurrent(data);
}

function totalCostsPerYearCurrent(data) {
  return totalDrugCost(true, data) + totalCostOfHospitalizationCurrent(data) + oralErAndPhysicianCostCurrent(data) + totalIndirectCostsCurrent(data);
}

function totalCostsPerYearNew(data) {
  return totalDrugCost(false, data) + totalCostOfHospitalizationNew(data) + totalCostErPhysician(data) + totalIndirectCostsNew(data);
}

function changedTotalCostsPerYear(data) {
  return totalCostsPerYearNew(data) - totalCostsPerYearCurrent(data);
}

export {
  drugCostPerDay ,
  drugCostPerYear ,
  patientsIndirectCostPerMonth ,
  patientsIndirectCostLai ,
  caregiversIndirectCostPerMonth ,
  caregiversIndirectCostLai ,
  oralHospitalizedCurrent ,
  oralHospitalizedNew ,
  oralRelapsedCurrent ,
  oralRelapsedNew ,
  laiHospitalized ,
  laiRelapsed ,
  oralHospAndRelapsedCurrent ,
  oralHospAndRelapsedNew ,
  oralRelapsedNotHospCurrent ,
  oralRelapsedNotHospNew ,
  oralRelapseOtherReasonsCurrent ,
  oralRelapseOtherReasonsNew ,
  oralNotHospNorRelapsedCurrent ,
  oralNotHospNorRelapsedNew ,
  totalOralPopulationCurrent ,
  totalOralPopulationNew ,
  laiHospAndRelapsed ,
  laiRelapsedNotHosp ,
  laiRelapseOtherReasons ,
  laiNotHospNorRelapsed ,
  totalLAIPopulation ,
  totalPopulationCurrent ,
  totalPopulationNew ,
  oralHospDaysFromHospAndRelapsedCurrent ,
  oralHospDaysFromHospAndRelapsedNew ,
  oralHospDaysFromRelapseOtherReasonsCurrent ,
  oralHospDaysFromRelapseOtherReasonsNew ,
  laiHospDaysFromHospAndRelapsed ,
  laiHospDaysFromRelapseOtherReasons ,
  totalHospitalizedDaysCurrent ,
  totalHospitalizedDaysNew ,
  annualChangeHospDays ,
  oralIncarcerationCaseCurrent ,
  oralIncarcerationCaseNew ,
  laiIncarcerationCase ,
  totalIncarcerationCaseNew ,
  annualChangeInCriminalCase ,
  drugCostFromDrugId ,
  totalDrugCost ,
  annualChangeInDrugCosts ,
  totalCostOfHospitalizationCurrent ,
  totalCostOfHospitalizationNew ,
  changeInHospitalizationCosts ,
  oralErAndPhysicianCostCurrent ,
  oralErAndPhysicianCostNew ,
  laiErAndPhysicianCost ,
  totalCostErPhysician ,
  changeInErPhysician ,
  laiAdministrationCosts ,
  indirectCostsPatientOralCurrent ,
  indirectCostsPatientOralNew ,
  indirectCostsPatientLai ,
  indirectCostsCaregiversOralCurrent ,
  indirectCostsCaregiversOralNew ,
  indirectCostsCaregiversLai ,
  totalIndirectCostsCurrent ,
  totalIndirectCostsNew ,
  changeInIndirectCosts ,
  totalCostsPerYearCurrent ,
  totalCostsPerYearNew ,
  changedTotalCostsPerYear ,
  
}
/* No side effect */
