// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ModalDialog$CTEM from "./ModalDialog.bs.js";
import * as MaterialUI_Button from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Button.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

function ModalError(Props) {
  var _openOpt = Props.open;
  var classNameOpt = Props.className;
  var onHandleClose = Props.onHandleClose;
  var msg = Props.msg;
  var _open = _openOpt !== undefined ? _openOpt : true;
  var className = classNameOpt !== undefined ? classNameOpt : "modal-custom";
  return React.createElement(ModalDialog$CTEM.make, {
              open: _open,
              className: className,
              onHandleClose: onHandleClose,
              children: React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(Caml_option.some(MaterialUIDataType.FlexDirection.column), 3, undefined), [
                        undefined,
                        undefined,
                        Caml_option.some(MaterialUIDataType.AlignItems.center),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        Caml_option.some(null),
                        undefined,
                        undefined
                      ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement("p", undefined, Utils$CTEM.textWithNewLine(msg))),
                            undefined,
                            undefined
                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement(Button, {
                                      children: "Okay",
                                      className: "btn-solid-color1",
                                      variant: MaterialUI_Button.Variant.contained,
                                      onClick: (function (param) {
                                          return Curry._1(onHandleClose, undefined);
                                        })
                                    })),
                            undefined,
                            undefined
                          ])))
            });
}

var make = ModalError;

export {
  make ,
  
}
/* react Not a pure module */
