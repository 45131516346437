// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";
import List$1 from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";

function SideBar(Props) {
  var menuSideOpt = Props.menuSide;
  var currentMenuOpt = Props.currentMenu;
  var menus = Props.menus;
  var onToggleStep = Props.onToggleStep;
  var children = Props.children;
  var menuSide = menuSideOpt !== undefined ? menuSideOpt : /* Left */0;
  var currentMenu = currentMenuOpt !== undefined ? currentMenuOpt : 0;
  var menusComponent = React.createElement("div", {
        className: "sidebar-menus"
      }, React.createElement(List$1, {
            children: $$Array.of_list(List.mapi((function (index, title) {
                        return React.createElement(ListItem, {
                                    button: true,
                                    onClick: (function (param) {
                                        return Curry._1(onToggleStep, index);
                                      }),
                                    className: "list-item" + (
                                      currentMenu === index ? " item-active" : ""
                                    ),
                                    children: React.createElement("div", {
                                          className: "list-item-content"
                                        }, React.createElement(Avatar, {
                                              children: String(index + 1 | 0),
                                              className: "topic-number"
                                            }), React.createElement("div", {
                                              className: "topic-content"
                                            }, title)),
                                    key: "list-item-" + String(index)
                                  });
                      }), menus)),
            component: "nav"
          }));
  var contentComponent = React.createElement("div", {
        className: "sidebar-content"
      }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                "sidebar-content-grid",
                Caml_option.some(null),
                undefined,
                undefined
              ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                    undefined,
                    Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                    undefined,
                    undefined,
                    undefined,
                    "title- display-flex",
                    Caml_option.some(null),
                    undefined,
                    undefined
                  ]), React.createElement(Avatar, {
                    children: String(currentMenu + 1 | 0),
                    className: "topic-number"
                  }), React.createElement("h3", {
                    style: {
                      margin: "7px 0px"
                    }
                  }, Caml_array.get($$Array.of_list(menus), currentMenu))), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                    undefined,
                    Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                    undefined,
                    undefined,
                    undefined,
                    "content-",
                    Caml_option.some(children),
                    undefined,
                    undefined
                  ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                    undefined,
                    Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                    undefined,
                    undefined,
                    undefined,
                    "step-controller",
                    Caml_option.some(null),
                    undefined,
                    undefined
                  ]), currentMenu > 0 ? React.createElement(Button, {
                      children: "< Back",
                      className: "back-button",
                      onClick: (function (param) {
                          return Curry._1(onToggleStep, currentMenu - 1 | 0);
                        })
                    }) : null, currentMenu < (List.length(menus) - 1 | 0) ? React.createElement(Button, {
                      children: "Next >",
                      className: "red-button",
                      onClick: (function (param) {
                          return Curry._1(onToggleStep, currentMenu + 1 | 0);
                        })
                    }) : null)));
  var components = menuSide ? React.createElement(React.Fragment, undefined, contentComponent, menusComponent) : React.createElement(React.Fragment, undefined, menusComponent, contentComponent);
  return React.createElement("div", {
              className: "sidebar-container"
            }, components);
}

var make = SideBar;

export {
  make ,
  
}
/* react Not a pure module */
