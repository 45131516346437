// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Section$CTEM from "../../Section.bs.js";
import * as CtemIcon$CTEM from "../../CtemIcon.bs.js";
import * as LAIIntro$CTEM from "./LAIIntro.bs.js";
import * as BottomBar$CTEM from "../../BottomBar.bs.js";
import * as LAIHeader$CTEM from "./LAIHeader.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as CostBreakdown$CTEM from "./CostBreakdown.bs.js";
import * as LAIOutcomeOne$CTEM from "./LAIOutcomeOne.bs.js";
import * as LAIOutcomeTwo$CTEM from "./LAIOutcomeTwo.bs.js";
import * as TemplateUtils$CTEM from "../TemplateUtils.bs.js";
import * as CostComparison$CTEM from "./CostComparison.bs.js";

function findCurrentSlide(targetSilde) {
  if (targetSilde > 4 || targetSilde < 0) {
    return /* LAIIntro */0;
  } else {
    return targetSilde;
  }
}

function currentSlideToNumber(targetSilde) {
  return targetSilde;
}

function topicElement(title, subTitle, showLogo) {
  return React.createElement("div", {
              className: "topic-slide"
            }, showLogo ? React.createElement(CtemIcon$CTEM.make, {
                    iconType: /* LogoShort */38,
                    className: "mini-logo"
                  }) : null, React.createElement("h1", undefined, title), subTitle !== undefined ? React.createElement("p", undefined, subTitle) : null);
}

function slideContent(subTitle, title, contentElement, padding) {
  var tmp = {
    title: title
  };
  if (subTitle !== undefined) {
    tmp.subTitle = Caml_option.valFromOption(subTitle);
  }
  return React.createElement("div", {
              className: "slide-content"
            }, React.createElement("div", {
                  className: "title"
                }, React.createElement(LAIHeader$CTEM.make, tmp)), React.createElement("div", {
                  className: "content",
                  style: {
                    padding: padding
                  }
                }, contentElement));
}

function TemplateLAI(Props) {
  var work = Props.work;
  var workNameOpt = Props.workName;
  var data = Props.data;
  var workName = workNameOpt !== undefined ? workNameOpt : "";
  var match = React.useReducer((function (state, action) {
          if (action.TAG === /* OnChangeSlide */0) {
            return {
                    currentSlide: action._0,
                    allSlide: state.allSlide,
                    startX: state.startX
                  };
          } else {
            return {
                    currentSlide: state.currentSlide,
                    allSlide: state.allSlide,
                    startX: action._0
                  };
          }
        }), {
        currentSlide: /* LAIIntro */0,
        allSlide: {
          hd: /* LAIIntro */0,
          tl: {
            hd: /* OutcomeOne */1,
            tl: {
              hd: /* OutcomeTwo */2,
              tl: {
                hd: /* CostComparison */3,
                tl: {
                  hd: /* CostBreakdown */4,
                  tl: /* [] */0
                }
              }
            }
          }
        },
        startX: 0
      });
  var dispatch = match[1];
  var state = match[0];
  return React.createElement("div", {
              className: "template-container template-lai",
              onTouchEnd: (function (touchEvent) {
                  var nextSlide = TemplateUtils$CTEM.slideController(false, state.currentSlide, state.startX, touchEvent);
                  Curry._1(dispatch, {
                        TAG: /* OnSetTouchStart */1,
                        _0: 0
                      });
                  if (nextSlide < List.length(state.allSlide)) {
                    return Curry._1(dispatch, {
                                TAG: /* OnChangeSlide */0,
                                _0: findCurrentSlide(nextSlide)
                              });
                  }
                  
                }),
              onTouchStart: (function (touchEvent) {
                  var startX = TemplateUtils$CTEM.slideController(false, state.currentSlide, state.startX, touchEvent);
                  if (startX >= 0) {
                    return Curry._1(dispatch, {
                                TAG: /* OnSetTouchStart */1,
                                _0: startX
                              });
                  }
                  
                })
            }, $$Array.of_list(List.mapi((function (index, slide) {
                        var tmp;
                        switch (slide) {
                          case /* LAIIntro */0 :
                              tmp = React.createElement("div", {
                                    className: "slide-content"
                                  }, React.createElement("div", {
                                        className: "title main-title"
                                      }, React.createElement(LAIHeader$CTEM.make, {
                                            title: work.modelName,
                                            subTitle: workName
                                          })), React.createElement("div", {
                                        className: "content text-center",
                                        style: {
                                          height: "calc(80% - 175px)"
                                        }
                                      }, React.createElement(LAIIntro$CTEM.make, {
                                            onChangeSlide: (function (slide) {
                                                return Curry._1(dispatch, {
                                                            TAG: /* OnChangeSlide */0,
                                                            _0: slide
                                                          });
                                              })
                                          })));
                              break;
                          case /* OutcomeOne */1 :
                              tmp = slideContent("", "OUTCOMES COMPARISON 1/2", React.createElement(LAIOutcomeOne$CTEM.make, {
                                        data: data
                                      }), "10px 2%");
                              break;
                          case /* OutcomeTwo */2 :
                              tmp = slideContent("", "OUTCOMES COMPARISON 2/2", React.createElement(LAIOutcomeTwo$CTEM.make, {
                                        data: data
                                      }), "10px 2%");
                              break;
                          case /* CostComparison */3 :
                              tmp = slideContent("", "COSTS COMPARISON", React.createElement(CostComparison$CTEM.make, {
                                        data: data,
                                        currencyCode: work.currency.code
                                      }), "10px 2%");
                              break;
                          case /* CostBreakdown */4 :
                              tmp = slideContent("", "OVERALL COST BREAKDOWN", React.createElement(CostBreakdown$CTEM.make, {
                                        countryName: work.countryName,
                                        data: data,
                                        currencyCode: work.currency.code
                                      }), "10px 2%");
                              break;
                          
                        }
                        return React.createElement(Section$CTEM.make, {
                                    className: state.currentSlide === slide ? "slide-fade" : "slide-disable",
                                    padding: "0px",
                                    children: tmp,
                                    key: "template-section-" + String(index)
                                  });
                      }), state.allSlide)), React.createElement(BottomBar$CTEM.make, {
                  currentSlide: state.currentSlide,
                  lastSlide: List.length(state.allSlide),
                  goToEditPage: (function (param) {
                      return ReasonReactRouter.push("/presentation/" + (String(work.id) + "/edit/"));
                    }),
                  onNextSlide: (function (nextSlide) {
                      return Curry._1(dispatch, {
                                  TAG: /* OnChangeSlide */0,
                                  _0: findCurrentSlide(nextSlide)
                                });
                    })
                }));
}

var make = TemplateLAI;

export {
  findCurrentSlide ,
  currentSlideToNumber ,
  topicElement ,
  slideContent ,
  make ,
  
}
/* react Not a pure module */
