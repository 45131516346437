// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils$CTEM from "../../../Utils.bs.js";

function LAIHeader(Props) {
  var titleOpt = Props.title;
  var subTitle = Props.subTitle;
  var title = titleOpt !== undefined ? titleOpt : "";
  return React.createElement("div", {
              className: "topic-slide"
            }, React.createElement("h1", undefined, Utils$CTEM.textWithNewLine(title)), subTitle !== undefined ? React.createElement("p", undefined, subTitle) : null);
}

var make = LAIHeader;

export {
  make ,
  
}
/* react Not a pure module */
