// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as DataTypes$CTEM from "../../../../DataTypes.bs.js";
import * as EncodeLAI$CTEM from "../EncodeLAI.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as LAIInputForm$CTEM from "./LAIInputForm.bs.js";
import * as RequestUtils$CTEM from "../../../../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as RequetLAIUtils$CTEM from "../RequetLAIUtils.bs.js";
import * as SavedWorkContext$CTEM from "../../../../Contexts/SavedWorkContext.bs.js";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import * as LAITargetedSchizophrenia$CTEM from "./LAITargetedSchizophrenia.bs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";

var percentageErrorElement = React.createElement("p", {
      style: {
        color: "red"
      }
    }, "*The percentages must add up to 100");

function getDrugFromId(drugs, drugId) {
  return List.find_opt((function (drug) {
                return drug.id === drugId;
              }), drugs);
}

function getNewLaiMarketShare(marketShareData, value) {
  var init = marketShareData.lai;
  var init$1 = marketShareData.oral;
  return {
          lai: {
            title: init.title,
            value: value,
            source: init.source
          },
          oral: {
            title: init$1.title,
            value: 100 - value,
            source: init$1.source
          },
          pp1m: marketShareData.pp1m,
          pp3m: marketShareData.pp3m
        };
}

function LAIATAndMS(Props) {
  var workId = Props.workId;
  var defaultData = Props.defaultData;
  var currentData = Props.currentData;
  var match = SavedWorkContext$CTEM.useSavedWorkResults(undefined);
  var updateData = match.updateData;
  var match$1 = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* RequestState */0 :
                return {
                        currentMarketShare: state.currentMarketShare,
                        drugsSelected: state.drugsSelected,
                        drugs: state.drugs,
                        tempValue: state.tempValue,
                        loadingData: action._0
                      };
            case /* UpdateMarketShare */1 :
                return {
                        currentMarketShare: action._0,
                        drugsSelected: state.drugsSelected,
                        drugs: state.drugs,
                        tempValue: state.tempValue,
                        loadingData: state.loadingData
                      };
            case /* UpdateDrugs */2 :
                return {
                        currentMarketShare: state.currentMarketShare,
                        drugsSelected: action._0,
                        drugs: state.drugs,
                        tempValue: state.tempValue,
                        loadingData: state.loadingData
                      };
            case /* AddDrug */3 :
                return {
                        currentMarketShare: state.currentMarketShare,
                        drugsSelected: Belt_List.add(state.drugsSelected, action._0),
                        drugs: state.drugs,
                        tempValue: state.tempValue,
                        loadingData: state.loadingData
                      };
            case /* RemoveDrug */4 :
                var drug = action._0;
                return {
                        currentMarketShare: state.currentMarketShare,
                        drugsSelected: List.filter(function (d) {
                                return d.id !== drug.id;
                              })(state.drugsSelected),
                        drugs: state.drugs,
                        tempValue: state.tempValue,
                        loadingData: state.loadingData
                      };
            
          }
        }), {
        currentMarketShare: currentData.marketShare,
        drugsSelected: /* [] */0,
        drugs: currentData.drugs,
        tempValue: undefined,
        loadingData: false
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var updateLaiData = function (encoder) {
    Curry._1(dispatch, {
          TAG: /* RequestState */0,
          _0: true
        });
    return RequetLAIUtils$CTEM.requestUpdateLAIData(workId, encoder, (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadSuccess */0,
                        _0: DataTypes$CTEM.Decode.work(json)
                      });
                  return Curry._1(dispatch, {
                              TAG: /* RequestState */0,
                              _0: false
                            });
                }), (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadFailed */1,
                        _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                      });
                  return Curry._1(dispatch, {
                              TAG: /* RequestState */0,
                              _0: false
                            });
                }));
  };
  var initialData = function (param) {
    Curry._1(dispatch, {
          TAG: /* UpdateMarketShare */1,
          _0: currentData.marketShare
        });
    var drugsSelected = List.filter(function (drug) {
            if (drug.marketShare.withoutLAI.value !== 0 || drug.marketShare.withLAI.value !== 0) {
              return true;
            } else {
              return List.mem(drug, state.drugsSelected);
            }
          })(currentData.drugs);
    return Curry._1(dispatch, {
                TAG: /* UpdateDrugs */2,
                _0: drugsSelected
              });
  };
  React.useEffect((function () {
          initialData(undefined);
          
        }), []);
  React.useEffect((function () {
          initialData(undefined);
          
        }), [currentData]);
  var tooltipLeftClass = "tooltip-infos-left";
  var marketShareOralPercentages = [0];
  var marketShareLaiPercentages = [state.currentMarketShare.lai.value];
  var allOralDrugsSelected = List.filter(function (drug) {
          return drug.drugType === /* OralDrug */0;
        })(state.drugsSelected);
  List.map((function (drug) {
          Caml_array.set(marketShareOralPercentages, 0, Caml_array.get(marketShareOralPercentages, 0) + drug.marketShare.withoutLAI.value);
          return Caml_array.set(marketShareLaiPercentages, 0, Caml_array.get(marketShareLaiPercentages, 0) + drug.marketShare.withLAI.value);
        }), allOralDrugsSelected);
  var updateDrugMSLaiFromLaiUsege = function (laiUsage) {
    var allDrugSelected = List.map((function (drug) {
            var strValue = (drug.marketShare.withoutLAI.value - drug.marketShare.withoutLAI.value * laiUsage / 100).toString();
            var value = Utils$CTEM.getFloatFromString(undefined, strValue, 0);
            var init = drug.marketShare;
            var init$1 = drug.marketShare.withLAI;
            return {
                    id: drug.id,
                    name: drug.name,
                    drugNameKey: drug.drugNameKey,
                    description: drug.description,
                    dailyDose: drug.dailyDose,
                    costPerUnit: drug.costPerUnit,
                    marketShare: {
                      oralKey: init.oralKey,
                      laiKey: init.laiKey,
                      withoutLAI: init.withoutLAI,
                      withLAI: {
                        title: init$1.title,
                        value: value,
                        source: init$1.source
                      }
                    },
                    drugType: drug.drugType
                  };
          }), allOralDrugsSelected);
    return updateLaiData(EncodeLAI$CTEM.drugsMarketShare(allDrugSelected, false));
  };
  var isOralError = Caml_array.get(marketShareOralPercentages, 0) > 100 || Caml_array.get(marketShareOralPercentages, 0) < 100 && List.length(allOralDrugsSelected) > 0;
  var isLaiError = Caml_array.get(marketShareLaiPercentages, 0) > 100 || state.currentMarketShare.lai.value > 100 || state.currentMarketShare.lai.value < 0 || Caml_array.get(marketShareLaiPercentages, 0) < 100 && List.length(allOralDrugsSelected) > 0;
  var match$2 = List.length(allOralDrugsSelected);
  var tmp;
  if (match$2 !== 0) {
    tmp = $$Array.of_list(List.mapi((function (index, drug) {
                return React.createElement(React.Fragment, {
                            children: null,
                            key: "ms-drug" + String(index)
                          }, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                    undefined,
                                    Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                    undefined,
                                    undefined,
                                    undefined,
                                    "bg-edit-form-middle-1 width-edit-form",
                                    Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                              inputMode: {
                                                TAG: /* FloatInput */1,
                                                _0: drug.marketShare.withoutLAI.value
                                              },
                                              title: drug.name,
                                              unit: "%",
                                              inputDisabled: state.loadingData,
                                              onValueUpdate: (function (value) {
                                                  var init = drug.marketShare;
                                                  var init$1 = drug.marketShare.withoutLAI;
                                                  var init$2 = drug.marketShare.withLAI;
                                                  var drug_id = drug.id;
                                                  var drug_name = drug.name;
                                                  var drug_drugNameKey = drug.drugNameKey;
                                                  var drug_description = drug.description;
                                                  var drug_dailyDose = drug.dailyDose;
                                                  var drug_costPerUnit = drug.costPerUnit;
                                                  var drug_marketShare = {
                                                    oralKey: init.oralKey,
                                                    laiKey: init.laiKey,
                                                    withoutLAI: {
                                                      title: init$1.title,
                                                      value: value,
                                                      source: init$1.source
                                                    },
                                                    withLAI: {
                                                      title: init$2.title,
                                                      value: value - value * state.currentMarketShare.lai.value / 100,
                                                      source: init$2.source
                                                    }
                                                  };
                                                  var drug_drugType = drug.drugType;
                                                  var drug$1 = {
                                                    id: drug_id,
                                                    name: drug_name,
                                                    drugNameKey: drug_drugNameKey,
                                                    description: drug_description,
                                                    dailyDose: drug_dailyDose,
                                                    costPerUnit: drug_costPerUnit,
                                                    marketShare: drug_marketShare,
                                                    drugType: drug_drugType
                                                  };
                                                  updateLaiData(EncodeLAI$CTEM.drugsMarketShare({
                                                            hd: drug$1,
                                                            tl: /* [] */0
                                                          }, true));
                                                  return updateLaiData(EncodeLAI$CTEM.drugsMarketShare({
                                                                  hd: drug$1,
                                                                  tl: /* [] */0
                                                                }, false));
                                                }),
                                              infoMode: /* Infos */{
                                                _0: drug.marketShare.withoutLAI.source
                                              },
                                              onRestore: (function (param) {
                                                  
                                                }),
                                              error: isOralError
                                            })),
                                    "" + (String(index) + "-1"),
                                    undefined
                                  ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                    undefined,
                                    Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                    undefined,
                                    undefined,
                                    undefined,
                                    "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                                    Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                              inputMode: {
                                                TAG: /* FloatInput */1,
                                                _0: drug.marketShare.withLAI.value
                                              },
                                              title: drug.name,
                                              tooltipClassName: tooltipLeftClass,
                                              unit: "%",
                                              inputDisabled: state.loadingData,
                                              onValueUpdate: (function (value) {
                                                  var init = drug.marketShare;
                                                  var init$1 = drug.marketShare.withLAI;
                                                  var drug_id = drug.id;
                                                  var drug_name = drug.name;
                                                  var drug_drugNameKey = drug.drugNameKey;
                                                  var drug_description = drug.description;
                                                  var drug_dailyDose = drug.dailyDose;
                                                  var drug_costPerUnit = drug.costPerUnit;
                                                  var drug_marketShare = {
                                                    oralKey: init.oralKey,
                                                    laiKey: init.laiKey,
                                                    withoutLAI: init.withoutLAI,
                                                    withLAI: {
                                                      title: init$1.title,
                                                      value: value,
                                                      source: init$1.source
                                                    }
                                                  };
                                                  var drug_drugType = drug.drugType;
                                                  var drug$1 = {
                                                    id: drug_id,
                                                    name: drug_name,
                                                    drugNameKey: drug_drugNameKey,
                                                    description: drug_description,
                                                    dailyDose: drug_dailyDose,
                                                    costPerUnit: drug_costPerUnit,
                                                    marketShare: drug_marketShare,
                                                    drugType: drug_drugType
                                                  };
                                                  return updateLaiData(EncodeLAI$CTEM.drugsMarketShare({
                                                                  hd: drug$1,
                                                                  tl: /* [] */0
                                                                }, false));
                                                }),
                                              infoMode: /* Infos */{
                                                _0: drug.marketShare.withLAI.source
                                              },
                                              onRestore: (function (param) {
                                                  
                                                }),
                                              error: isLaiError
                                            })),
                                    "" + (String(index) + "-2"),
                                    undefined
                                  ])));
              }), Belt_List.sort(allOralDrugsSelected, (function (a, b) {
                    return Caml_primitive.caml_string_compare(a.name, b.name);
                  }))));
  } else {
    var noOralText = "No oral medications selected";
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                  undefined,
                  undefined,
                  undefined,
                  "bg-edit-form-middle-1 text-center width-edit-form",
                  Caml_option.some(React.createElement("p", undefined, noOralText)),
                  undefined,
                  undefined
                ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                  undefined,
                  undefined,
                  undefined,
                  "bg-edit-form-middle-2 text-center width-edit-form margin-edit-form",
                  Caml_option.some(React.createElement("p", undefined, noOralText)),
                  undefined,
                  undefined
                ])));
  }
  return React.createElement(React.Fragment, undefined, React.createElement(LAITargetedSchizophrenia$CTEM.make, {
                  targetedSchizophrenia: currentData.targetedSchizophrenia,
                  defaultData: defaultData,
                  loading: state.loadingData,
                  onSave: (function (newTarget) {
                      return updateLaiData(EncodeLAI$CTEM.targetedSchizophrenia(newTarget));
                    })
                }), React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(null),
                      undefined,
                      undefined
                    ]), state.currentMarketShare.lai.value < 0 || state.currentMarketShare.lai.value > 100 || state.currentMarketShare.pp1m.value < 0 || state.currentMarketShare.pp1m.value > 100 ? React.createElement(React.Fragment, undefined, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined
                              ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                undefined,
                                Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                undefined,
                                undefined,
                                undefined,
                                "text-right",
                                Caml_option.some(percentageErrorElement),
                                undefined,
                                undefined
                              ]))) : null, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          "bg-edit-form-top-1 width-edit-form",
                          Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        Caml_option.some(null),
                                        undefined,
                                        undefined
                                      ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            Caml_option.some(React.createElement("p", {
                                                      style: {
                                                        margin: "0px"
                                                      }
                                                    }, "Please select the oral medications used in the current environment, and specify market share")),
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            Caml_option.some(React.createElement("p", undefined, "Showing medications available")),
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            "country-drugs-container",
                                            Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          Caml_option.some($$Array.of_list(List.mapi((function (index, drug) {
                                                                          var match = List.find_opt((function (drugSelected) {
                                                                                  return drugSelected.id === drug.id;
                                                                                }), state.drugsSelected);
                                                                          var checked = match !== undefined;
                                                                          return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                                                          undefined,
                                                                                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                                                                          undefined,
                                                                                          undefined,
                                                                                          Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                                                                          undefined,
                                                                                          Caml_option.some(React.createElement(FormControlLabel, {
                                                                                                    control: React.createElement(Checkbox, {
                                                                                                          checked: checked,
                                                                                                          color: "primary",
                                                                                                          name: drug.name,
                                                                                                          onChange: (function (param) {
                                                                                                              if (!checked) {
                                                                                                                return Curry._1(dispatch, {
                                                                                                                            TAG: /* AddDrug */3,
                                                                                                                            _0: drug
                                                                                                                          });
                                                                                                              }
                                                                                                              var init = drug.marketShare;
                                                                                                              var init$1 = drug.marketShare.withoutLAI;
                                                                                                              var init$2 = drug.marketShare.withLAI;
                                                                                                              var defaultDrug_id = drug.id;
                                                                                                              var defaultDrug_name = drug.name;
                                                                                                              var defaultDrug_drugNameKey = drug.drugNameKey;
                                                                                                              var defaultDrug_description = drug.description;
                                                                                                              var defaultDrug_dailyDose = drug.dailyDose;
                                                                                                              var defaultDrug_costPerUnit = drug.costPerUnit;
                                                                                                              var defaultDrug_marketShare = {
                                                                                                                oralKey: init.oralKey,
                                                                                                                laiKey: init.laiKey,
                                                                                                                withoutLAI: {
                                                                                                                  title: init$1.title,
                                                                                                                  value: 0,
                                                                                                                  source: init$1.source
                                                                                                                },
                                                                                                                withLAI: {
                                                                                                                  title: init$2.title,
                                                                                                                  value: 0,
                                                                                                                  source: init$2.source
                                                                                                                }
                                                                                                              };
                                                                                                              var defaultDrug_drugType = drug.drugType;
                                                                                                              var defaultDrug = {
                                                                                                                id: defaultDrug_id,
                                                                                                                name: defaultDrug_name,
                                                                                                                drugNameKey: defaultDrug_drugNameKey,
                                                                                                                description: defaultDrug_description,
                                                                                                                dailyDose: defaultDrug_dailyDose,
                                                                                                                costPerUnit: defaultDrug_costPerUnit,
                                                                                                                marketShare: defaultDrug_marketShare,
                                                                                                                drugType: defaultDrug_drugType
                                                                                                              };
                                                                                                              updateLaiData(EncodeLAI$CTEM.drugsMarketShare({
                                                                                                                        hd: defaultDrug,
                                                                                                                        tl: /* [] */0
                                                                                                                      }, true));
                                                                                                              updateLaiData(EncodeLAI$CTEM.drugsMarketShare({
                                                                                                                        hd: defaultDrug,
                                                                                                                        tl: /* [] */0
                                                                                                                      }, false));
                                                                                                              return Curry._1(dispatch, {
                                                                                                                          TAG: /* RemoveDrug */4,
                                                                                                                          _0: drug
                                                                                                                        });
                                                                                                            })
                                                                                                        }),
                                                                                                    label: drug.name,
                                                                                                    key: "drug-item-" + String(index)
                                                                                                  })),
                                                                                          undefined,
                                                                                          undefined
                                                                                        ]));
                                                                        }), List.filter(function (drug) {
                                                                              return drug.drugType === /* OralDrug */0;
                                                                            })(Belt_List.sort(state.drugs, (function (a, b) {
                                                                                  return Caml_primitive.caml_string_compare(a.name, b.name);
                                                                                })))))),
                                                          undefined,
                                                          undefined
                                                        ]))),
                                            undefined,
                                            undefined
                                          ])))),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          "width-edit-form margin-edit-form",
                          Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        "bg-edit-form-2",
                                        Caml_option.some(null),
                                        undefined,
                                        undefined
                                      ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            Caml_option.some(React.createElement("b", undefined, React.createElement("p", {
                                                          style: {
                                                            display: "inline-block",
                                                            margin: "0px"
                                                          }
                                                        }, "Please specify expected usage of ORALs and LAI", React.createElement("span", {
                                                              style: {
                                                                color: "red"
                                                              }
                                                            }, "*")))),
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                                      inputMode: {
                                                        TAG: /* FloatInput */1,
                                                        _0: state.currentMarketShare.oral.value
                                                      },
                                                      title: state.currentMarketShare.oral.title,
                                                      titleWeight: "normal",
                                                      tooltipClassName: tooltipLeftClass,
                                                      unit: "%",
                                                      inputDisabled: true,
                                                      infoMode: state.currentMarketShare.oral.value === defaultData.marketShare.oral.value ? /* Infos */({
                                                            _0: state.currentMarketShare.oral.source
                                                          }) : /* Restore */0,
                                                      onRestore: (function (param) {
                                                          var init = currentData.marketShare;
                                                          var init$1 = currentData.marketShare.lai;
                                                          var init$2 = currentData.marketShare.oral;
                                                          var marketShare_lai = {
                                                            title: init$1.title,
                                                            value: defaultData.marketShare.lai.value,
                                                            source: init$1.source
                                                          };
                                                          var marketShare_oral = {
                                                            title: init$2.title,
                                                            value: defaultData.marketShare.oral.value,
                                                            source: init$2.source
                                                          };
                                                          var marketShare_pp1m = init.pp1m;
                                                          var marketShare_pp3m = init.pp3m;
                                                          var marketShare = {
                                                            lai: marketShare_lai,
                                                            oral: marketShare_oral,
                                                            pp1m: marketShare_pp1m,
                                                            pp3m: marketShare_pp3m
                                                          };
                                                          updateLaiData(EncodeLAI$CTEM.marketShare(marketShare));
                                                          return updateDrugMSLaiFromLaiUsege(defaultData.marketShare.lai.value);
                                                        })
                                                    })),
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                                      inputMode: {
                                                        TAG: /* FloatInput */1,
                                                        _0: state.currentMarketShare.lai.value
                                                      },
                                                      title: state.currentMarketShare.lai.title,
                                                      titleWeight: "normal",
                                                      tooltipClassName: tooltipLeftClass,
                                                      unit: "%",
                                                      inputDisabled: state.loadingData,
                                                      onValueUpdate: (function (value) {
                                                          var marketShare = getNewLaiMarketShare(state.currentMarketShare, value);
                                                          updateLaiData(EncodeLAI$CTEM.marketShare(marketShare));
                                                          return updateDrugMSLaiFromLaiUsege(marketShare.lai.value);
                                                        }),
                                                      infoMode: state.currentMarketShare.lai.value === defaultData.marketShare.lai.value ? /* Infos */({
                                                            _0: state.currentMarketShare.lai.source
                                                          }) : /* Restore */0,
                                                      onRestore: (function (param) {
                                                          var marketShare = getNewLaiMarketShare(currentData.marketShare, defaultData.marketShare.lai.value);
                                                          updateLaiData(EncodeLAI$CTEM.marketShare(marketShare));
                                                          return updateDrugMSLaiFromLaiUsege(defaultData.marketShare.lai.value);
                                                        }),
                                                      error: state.currentMarketShare.lai.value > 100 || state.currentMarketShare.lai.value < 0
                                                    })),
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            Caml_option.some(React.createElement("b", undefined, React.createElement("p", {
                                                          style: {
                                                            display: "inline-block",
                                                            marginBottom: "0px"
                                                          }
                                                        }, "Please specify expected usage among LAI", React.createElement("br", undefined), "(PP1M and PP3M)", React.createElement("span", {
                                                              style: {
                                                                color: "red"
                                                              }
                                                            }, "*")))),
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                                      inputMode: {
                                                        TAG: /* FloatInput */1,
                                                        _0: state.currentMarketShare.pp1m.value
                                                      },
                                                      title: state.currentMarketShare.pp1m.title,
                                                      titleWeight: "normal",
                                                      tooltipClassName: tooltipLeftClass,
                                                      unit: "%",
                                                      inputDisabled: state.loadingData,
                                                      onValueUpdate: (function (value) {
                                                          var init = state.currentMarketShare;
                                                          var init$1 = state.currentMarketShare.pp1m;
                                                          var init$2 = state.currentMarketShare.pp3m;
                                                          return updateLaiData(EncodeLAI$CTEM.marketShare({
                                                                          lai: init.lai,
                                                                          oral: init.oral,
                                                                          pp1m: {
                                                                            title: init$1.title,
                                                                            value: value,
                                                                            source: init$1.source
                                                                          },
                                                                          pp3m: {
                                                                            title: init$2.title,
                                                                            value: 100 - value,
                                                                            source: init$2.source
                                                                          }
                                                                        }));
                                                        }),
                                                      infoMode: state.currentMarketShare.pp1m.value === defaultData.marketShare.pp1m.value ? /* Infos */({
                                                            _0: state.currentMarketShare.pp1m.source
                                                          }) : /* Restore */0,
                                                      onRestore: (function (param) {
                                                          var init = currentData.marketShare;
                                                          var init$1 = state.currentMarketShare.pp1m;
                                                          var init$2 = state.currentMarketShare.pp3m;
                                                          return updateLaiData(EncodeLAI$CTEM.marketShare({
                                                                          lai: init.lai,
                                                                          oral: init.oral,
                                                                          pp1m: {
                                                                            title: init$1.title,
                                                                            value: defaultData.marketShare.pp1m.value,
                                                                            source: init$1.source
                                                                          },
                                                                          pp3m: {
                                                                            title: init$2.title,
                                                                            value: defaultData.marketShare.pp3m.value,
                                                                            source: init$2.source
                                                                          }
                                                                        }));
                                                        }),
                                                      error: state.currentMarketShare.pp1m.value > 100 || state.currentMarketShare.pp1m.value < 0
                                                    })),
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                                      inputMode: {
                                                        TAG: /* FloatInput */1,
                                                        _0: state.currentMarketShare.pp3m.value
                                                      },
                                                      title: state.currentMarketShare.pp3m.title,
                                                      titleWeight: "normal",
                                                      tooltipClassName: tooltipLeftClass,
                                                      unit: "%",
                                                      inputDisabled: true,
                                                      infoMode: state.currentMarketShare.pp3m.value === defaultData.marketShare.pp3m.value ? /* Infos */({
                                                            _0: state.currentMarketShare.pp3m.source
                                                          }) : /* Restore */0,
                                                      onRestore: (function (param) {
                                                          var init = currentData.marketShare;
                                                          var init$1 = state.currentMarketShare.pp1m;
                                                          var init$2 = state.currentMarketShare.pp3m;
                                                          return updateLaiData(EncodeLAI$CTEM.marketShare({
                                                                          lai: init.lai,
                                                                          oral: init.oral,
                                                                          pp1m: {
                                                                            title: init$1.title,
                                                                            value: defaultData.marketShare.pp1m.value,
                                                                            source: init$1.source
                                                                          },
                                                                          pp3m: {
                                                                            title: init$2.title,
                                                                            value: defaultData.marketShare.pp3m.value,
                                                                            source: init$2.source
                                                                          }
                                                                        }));
                                                        }),
                                                      error: state.currentMarketShare.pp3m.value > 100 || state.currentMarketShare.pp3m.value < 0
                                                    })),
                                            undefined,
                                            undefined
                                          ])))),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(React.createElement("div", {
                                    style: {
                                      height: "10px"
                                    }
                                  })),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          "bg-edit-form-top-1 width-edit-form",
                          Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        Caml_option.some(null),
                                        undefined,
                                        undefined
                                      ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            "text-center",
                                            Caml_option.some(React.createElement("b", undefined, React.createElement("p", {
                                                          style: {
                                                            marginTop: "12px"
                                                          }
                                                        }, "Market share"))),
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined
                                          ])))),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          "bg-edit-form-top-2 width-edit-form margin-edit-form",
                          Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        Caml_option.some(null),
                                        undefined,
                                        undefined
                                      ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            "text-center",
                                            Caml_option.some(React.createElement("b", undefined, React.createElement("p", {
                                                          style: {
                                                            marginTop: "12px"
                                                          }
                                                        }, "Market share"))),
                                            undefined,
                                            undefined
                                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                            undefined,
                                            Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined
                                          ])))),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          "bg-edit-form-middle-1 width-edit-form",
                          undefined,
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                          Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                    inputMode: {
                                      TAG: /* FloatInput */1,
                                      _0: state.currentMarketShare.lai.value
                                    },
                                    title: "LAI",
                                    tooltipClassName: tooltipLeftClass,
                                    unit: "%",
                                    inputDisabled: state.loadingData,
                                    onValueUpdate: (function (value) {
                                        var marketShare = getNewLaiMarketShare(state.currentMarketShare, value);
                                        updateLaiData(EncodeLAI$CTEM.marketShare(marketShare));
                                        return updateDrugMSLaiFromLaiUsege(marketShare.lai.value);
                                      }),
                                    infoMode: state.currentMarketShare.lai.value === defaultData.marketShare.lai.value ? /* Infos */({
                                          _0: state.currentMarketShare.lai.source
                                        }) : /* Restore */0,
                                    onRestore: (function (param) {
                                        var marketShare = getNewLaiMarketShare(currentData.marketShare, defaultData.marketShare.lai.value);
                                        updateLaiData(EncodeLAI$CTEM.marketShare(marketShare));
                                        return updateDrugMSLaiFromLaiUsege(defaultData.marketShare.lai.value);
                                      }),
                                    error: isLaiError
                                  })),
                          undefined,
                          undefined
                        ])), tmp, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          "bg-edit-form-bottom-1 width-edit-form",
                          undefined,
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          "bg-edit-form-bottom-2 width-edit-form margin-edit-form",
                          undefined,
                          undefined,
                          undefined
                        ]))), React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(null),
                      undefined,
                      undefined
                    ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(isOralError ? percentageErrorElement : null),
                          undefined,
                          undefined
                        ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                          undefined,
                          Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                          undefined,
                          undefined,
                          undefined,
                          "text-right",
                          Caml_option.some(isLaiError ? percentageErrorElement : null),
                          undefined,
                          undefined
                        ]))));
}

var drugInfo = "Estimated market share among antipsychotics prescribed in the hospital";

var make = LAIATAndMS;

export {
  drugInfo ,
  percentageErrorElement ,
  getDrugFromId ,
  getNewLaiMarketShare ,
  make ,
  
}
/* percentageErrorElement Not a pure module */
