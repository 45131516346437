// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils$CTEM from "../../../../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as FloatInput$CTEM from "../../../FloatInput.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as RestoreButton$CTEM from "../../../RestoreButton.bs.js";
import * as InputComponent$CTEM from "../../../InputComponent.bs.js";
import Grid from "@material-ui/core/Grid";

function LAIInputForm(Props) {
  var inputModeOpt = Props.inputMode;
  var title = Props.title;
  var titleWeightOpt = Props.titleWeight;
  var subTitleOpt = Props.subTitle;
  var tooltipClassNameOpt = Props.tooltipClassName;
  var unit = Props.unit;
  var inputDisabledOpt = Props.inputDisabled;
  var digitOpt = Props.digit;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var onValueUpdate = Props.onValueUpdate;
  var onTextUpdate = Props.onTextUpdate;
  var infoMode = Props.infoMode;
  var onRestore = Props.onRestore;
  var defaultValue = Props.defaultValue;
  var errorOpt = Props.error;
  var inputMode = inputModeOpt !== undefined ? inputModeOpt : ({
        TAG: /* TextInput */0,
        _0: ""
      });
  var titleWeight = titleWeightOpt !== undefined ? titleWeightOpt : "bold";
  var subTitle = subTitleOpt !== undefined ? subTitleOpt : "";
  var tooltipClassName = tooltipClassNameOpt !== undefined ? tooltipClassNameOpt : "";
  var inputDisabled = inputDisabledOpt !== undefined ? inputDisabledOpt : false;
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var error = errorOpt !== undefined ? errorOpt : false;
  var match;
  var exit = 0;
  if (title === "" && subTitle === "") {
    match = [
      React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                undefined,
                Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
              ])),
      6,
      4
    ];
  } else {
    exit = 1;
  }
  if (exit === 1) {
    match = [
      React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                undefined,
                Caml_option.some(MaterialUIDataType.GridSize.size(7)),
                undefined,
                undefined,
                undefined,
                undefined,
                Caml_option.some(null),
                undefined,
                undefined
              ]), React.createElement("p", {
                className: "input-form-title",
                style: {
                  fontWeight: titleWeight
                }
              }, Utils$CTEM.textWithNewLine(title)), React.createElement("p", {
                className: "input-form-subtitle"
              }, Utils$CTEM.textWithNewLine(subTitle))),
      3,
      2
    ];
  }
  var tmp;
  if (inputMode.TAG === /* TextInput */0) {
    var tmp$1 = {
      mode: /* ModifyData */1,
      fullWidth: false,
      value: inputMode._0,
      disabled: inputDisabled
    };
    if (onChange !== undefined) {
      tmp$1.onChange = Caml_option.valFromOption(onChange);
    }
    if (onBlur !== undefined) {
      tmp$1.onBlur = Caml_option.valFromOption(onBlur);
    }
    if (defaultValue !== undefined) {
      tmp$1.defaultValue = Caml_option.valFromOption(defaultValue);
    }
    tmp = React.createElement(InputComponent$CTEM.make, tmp$1);
  } else {
    var tmp$2 = {
      value: inputMode._0,
      disabled: inputDisabled,
      digit: digit,
      error: error
    };
    if (onValueUpdate !== undefined) {
      tmp$2.onValueUpdate = Caml_option.valFromOption(onValueUpdate);
    }
    if (onTextUpdate !== undefined) {
      tmp$2.onTextUpdate = Caml_option.valFromOption(onTextUpdate);
    }
    tmp = React.createElement(FloatInput$CTEM.make, tmp$2);
  }
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "edit-input-form",
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), match[0], React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(match[1])),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(tmp),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(match[2])),
                      undefined,
                      undefined,
                      undefined,
                      "last-col text-center",
                      Caml_option.some(null),
                      undefined,
                      undefined
                    ]), React.createElement("p", {
                      className: "input-form-unit"
                    }, unit), React.createElement(RestoreButton$CTEM.make, {
                      dynamicTooltipPosition: tooltipClassName === "",
                      tooltipClassName: tooltipClassName,
                      mode: infoMode,
                      onRestore: onRestore
                    })));
}

var make = LAIInputForm;

export {
  make ,
  
}
/* react Not a pure module */
