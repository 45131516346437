// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Json_encode from "@glennsl/bs-json/lib/es6/src/Json_encode.bs.js";
import * as Caml_splice_call from "bs-platform/lib/es6/caml_splice_call.js";
import * as LAICalculation$CTEM from "../LAICalculation.bs.js";

function encodeFromKey(key, encoder) {
  return [
          key,
          encoder
        ];
}

function encodeObjectFromKey(key, encoder) {
  return Json_encode.object_({
              hd: [
                key,
                encoder
              ],
              tl: /* [] */0
            });
}

function inputDatas(encoder) {
  return Json_encode.object_({
              hd: [
                "normal_input_data",
                encoder
              ],
              tl: /* [] */0
            });
}

function targetedSchizophrenia(value) {
  return inputDatas(encodeObjectFromKey("targeted_patients", encodeObjectFromKey("value", String(value))));
}

function healthcareAndIncarcerationKeys(overallHealthcare, incarceration) {
  return {
          hd: [
            "oral_hospitalization_rate",
            encodeObjectFromKey("value", overallHealthcare.oral.hospitalizationRate.value.toString())
          ],
          tl: {
            hd: [
              "oral_hospitalization_length_of_stay",
              encodeObjectFromKey("value", overallHealthcare.oral.lengthOfStay.value.toString())
            ],
            tl: {
              hd: [
                "oral_hospitalization_relapse_rate",
                encodeObjectFromKey("value", overallHealthcare.oral.relapseRate.value.toString())
              ],
              tl: {
                hd: [
                  "lai_hospitalization_rate",
                  encodeObjectFromKey("value", overallHealthcare.lais.hospitalizationRate.value.toString())
                ],
                tl: {
                  hd: [
                    "lai_hospitalization_length_of_stay",
                    encodeObjectFromKey("value", overallHealthcare.lais.lengthOfStay.value.toString())
                  ],
                  tl: {
                    hd: [
                      "oral_avg_relapse_rate",
                      encodeObjectFromKey("value", overallHealthcare.oral.avgRelapseRate.value.toString())
                    ],
                    tl: {
                      hd: [
                        "lai_avg_relapse_rate",
                        encodeObjectFromKey("value", overallHealthcare.lais.avgRelapseRate.value.toString())
                      ],
                      tl: {
                        hd: [
                          "oral_incarcerated_patients_rate",
                          encodeObjectFromKey("value", incarceration.oralPatients.value.toString())
                        ],
                        tl: {
                          hd: [
                            "lai_incarcerated_patients_rate",
                            encodeObjectFromKey("value", incarceration.pp1mPatients.value.toString())
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        };
}

function healthcareAndIncarceration(overallHealthcare, incarceration) {
  return inputDatas(Json_encode.object_(healthcareAndIncarcerationKeys(overallHealthcare, incarceration)));
}

function medicalCosts(otherMedicalCost) {
  return {
          hd: [
            "hospitalization_cost",
            encodeObjectFromKey("value", otherMedicalCost.hospitalizationCost.value.toString())
          ],
          tl: {
            hd: [
              "er_cost",
              encodeObjectFromKey("value", otherMedicalCost.erAndPhysicianVisitCost.value.toString())
            ],
            tl: {
              hd: [
                "oral_pp1m_total_physician_visit",
                encodeObjectFromKey("value", String(otherMedicalCost.erAndPhysicianVisitPP1M.value))
              ],
              tl: {
                hd: [
                  "pp3m_total_physician_visit",
                  encodeObjectFromKey("value", String(otherMedicalCost.erAndPhysicianVisitPP3M.value))
                ],
                tl: {
                  hd: [
                    "admin_cost_lai",
                    encodeObjectFromKey("value", otherMedicalCost.costPerAdministrationOfLAI.value.toString())
                  ],
                  tl: /* [] */0
                }
              }
            }
          }
        };
}

function indirectPatientCosts(indirectCostPatient) {
  return {
          hd: [
            "oral_unemployment_rate",
            encodeObjectFromKey("value", indirectCostPatient.unemploymentRate.value.toString())
          ],
          tl: {
            hd: [
              "oral_absent_hours",
              encodeObjectFromKey("value", indirectCostPatient.absentHours.value.toString())
            ],
            tl: {
              hd: [
                "oral_min_daily_wage",
                encodeObjectFromKey("value", indirectCostPatient.minimumDailyWage.value.toString())
              ],
              tl: {
                hd: [
                  "oral_working_days_per_month",
                  encodeObjectFromKey("value", String(indirectCostPatient.workingDaysPerMonth.value))
                ],
                tl: {
                  hd: [
                    "oral_patient_indirect_cost_per_month",
                    encodeObjectFromKey("value", LAICalculation$CTEM.patientsIndirectCostPerMonth(indirectCostPatient).toString())
                  ],
                  tl: {
                    hd: [
                      "lai_unemployment_rate",
                      encodeObjectFromKey("value", indirectCostPatient.improvementRateWithLAIs.value.toString())
                    ],
                    tl: {
                      hd: [
                        "lai_patient_indirect_cost_per_month",
                        encodeObjectFromKey("value", LAICalculation$CTEM.patientsIndirectCostLai(indirectCostPatient).toString())
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        };
}

function indirectCaregiverCosts(indirectCosts) {
  return {
          hd: [
            "oral_caregiver_work_hours",
            encodeObjectFromKey("value", String(indirectCosts.indirectCostCaregiver.workHour.value))
          ],
          tl: {
            hd: [
              "oral_caregiver_cost_per_month",
              encodeObjectFromKey("value", LAICalculation$CTEM.caregiversIndirectCostPerMonth(indirectCosts).toString())
            ],
            tl: {
              hd: [
                "lai_caregiver_work_hours",
                encodeObjectFromKey("value", indirectCosts.indirectCostCaregiver.improvementInProductivity.value.toString())
              ],
              tl: {
                hd: [
                  "lai_caregiver_cost_per_month",
                  encodeObjectFromKey("value", LAICalculation$CTEM.caregiversIndirectCostLai(indirectCosts).toString())
                ],
                tl: /* [] */0
              }
            }
          }
        };
}

function otherCosts(indirectCosts) {
  var costs = $$Array.of_list(medicalCosts(indirectCosts.otherMedicalCost));
  Caml_splice_call.spliceObjApply(costs, "push", [$$Array.of_list(indirectPatientCosts(indirectCosts.indirectCostPatient))]);
  Caml_splice_call.spliceObjApply(costs, "push", [$$Array.of_list(indirectCaregiverCosts(indirectCosts))]);
  return inputDatas(Json_encode.object_($$Array.to_list(costs)));
}

function drugData(drug) {
  var match = drug.drugType;
  var drugTypeString = match ? "LAI" : "ORAL";
  return Json_encode.object_({
              hd: [
                "drug_name",
                drug.name
              ],
              tl: {
                hd: [
                  "drug_name_key",
                  drug.drugNameKey
                ],
                tl: {
                  hd: [
                    "drug_type",
                    drugTypeString
                  ],
                  tl: {
                    hd: [
                      "ddd",
                      encodeObjectFromKey("value", drug.dailyDose.value.toString())
                    ],
                    tl: {
                      hd: [
                        "price",
                        encodeObjectFromKey("value", drug.costPerUnit.value.toString())
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function drugsData(drugs) {
  var drugsEncoded = $$Array.of_list(List.map(drugData, drugs));
  return Json_encode.object_({
              hd: [
                "drugs_data",
                drugsEncoded
              ],
              tl: /* [] */0
            });
}

function drugMarketShareKeys(drug, isOral) {
  return {
          hd: [
            "drug_name_key",
            drug.drugNameKey
          ],
          tl: {
            hd: [
              "key",
              isOral ? drug.marketShare.oralKey : drug.marketShare.laiKey
            ],
            tl: {
              hd: [
                "value",
                (
                    isOral ? drug.marketShare.withoutLAI.value : drug.marketShare.withLAI.value
                  ).toString()
              ],
              tl: /* [] */0
            }
          }
        };
}

function drugMarketShare(drug, isOral) {
  return Json_encode.object_(drugMarketShareKeys(drug, isOral));
}

function drugsMarketShare(drugs, isOral) {
  var drugsEncoded = $$Array.of_list(List.map((function (drug) {
              return Json_encode.object_(drugMarketShareKeys(drug, isOral));
            }), drugs));
  return Json_encode.object_({
              hd: [
                "market_shares",
                drugsEncoded
              ],
              tl: /* [] */0
            });
}

function marketShareKeys(marketShare) {
  return {
          hd: [
            "lai_exp_usage",
            encodeObjectFromKey("value", marketShare.lai.value.toString())
          ],
          tl: {
            hd: [
              "oral_exp_usage",
              encodeObjectFromKey("value", marketShare.oral.value.toString())
            ],
            tl: {
              hd: [
                "lai_pp1m_exp_usage",
                encodeObjectFromKey("value", marketShare.pp1m.value.toString())
              ],
              tl: {
                hd: [
                  "lai_pp3m_exp_usage",
                  encodeObjectFromKey("value", marketShare.pp3m.value.toString())
                ],
                tl: /* [] */0
              }
            }
          }
        };
}

function marketShare(marketShare$1) {
  return inputDatas(Json_encode.object_(marketShareKeys(marketShare$1)));
}

function laiData(laiData$1) {
  var targeted_0 = [
    "targeted_patients",
    encodeObjectFromKey("value", String(laiData$1.targetedSchizophrenia.value))
  ];
  var targeted = {
    hd: targeted_0,
    tl: /* [] */0
  };
  var inputDataMerged = $$Array.of_list(targeted);
  Caml_splice_call.spliceObjApply(inputDataMerged, "push", [$$Array.of_list(marketShareKeys(laiData$1.marketShare))]);
  Caml_splice_call.spliceObjApply(inputDataMerged, "push", [$$Array.of_list(healthcareAndIncarcerationKeys(laiData$1.overallHealthcare, laiData$1.indirectCosts.incarceration))]);
  Caml_splice_call.spliceObjApply(inputDataMerged, "push", [$$Array.of_list(medicalCosts(laiData$1.indirectCosts.otherMedicalCost))]);
  Caml_splice_call.spliceObjApply(inputDataMerged, "push", [$$Array.of_list(indirectPatientCosts(laiData$1.indirectCosts.indirectCostPatient))]);
  Caml_splice_call.spliceObjApply(inputDataMerged, "push", [$$Array.of_list(indirectCaregiverCosts(laiData$1.indirectCosts))]);
  var inputDatas = Json_encode.object_($$Array.to_list(inputDataMerged));
  var oralDrugs = List.map((function (drug) {
          return Json_encode.object_(drugMarketShareKeys(drug, true));
        }), List.filter(function (drug) {
              return drug.drugType === /* OralDrug */0;
            })(laiData$1.drugs));
  var laiDrugs = List.map((function (drug) {
          return Json_encode.object_(drugMarketShareKeys(drug, false));
        }), List.filter(function (drug) {
              return drug.drugType === /* OralDrug */0;
            })(laiData$1.drugs));
  var drugMarketShareDecoded = $$Array.of_list(oralDrugs);
  Caml_splice_call.spliceObjApply(drugMarketShareDecoded, "push", [$$Array.of_list(laiDrugs)]);
  var drugsDataDecoded = $$Array.of_list(List.map(drugData, laiData$1.drugs));
  return Json_encode.object_({
              hd: [
                "normal_input_data",
                inputDatas
              ],
              tl: {
                hd: [
                  "drugs_data",
                  drugsDataDecoded
                ],
                tl: {
                  hd: [
                    "market_shares",
                    drugMarketShareDecoded
                  ],
                  tl: /* [] */0
                }
              }
            });
}

export {
  encodeFromKey ,
  encodeObjectFromKey ,
  inputDatas ,
  targetedSchizophrenia ,
  healthcareAndIncarcerationKeys ,
  healthcareAndIncarceration ,
  medicalCosts ,
  indirectPatientCosts ,
  indirectCaregiverCosts ,
  otherCosts ,
  drugData ,
  drugsData ,
  drugMarketShareKeys ,
  drugMarketShare ,
  drugsMarketShare ,
  marketShareKeys ,
  marketShare ,
  laiData ,
  
}
/* No side effect */
