// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as CtemIcon$CTEM from "./CtemIcon.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";

function ChartIconsRow(Props) {
  var rowSize = Props.rowSize;
  var icons = Props.icons;
  var fillOpt = Props.fill;
  var fill = fillOpt !== undefined ? fillOpt : "black";
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.true_),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    ])), $$Array.of_list(List.mapi((function (index, iconType) {
                        return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(rowSize)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        Caml_option.some(React.createElement(CtemIcon$CTEM.make, {
                                                  iconType: iconType,
                                                  fill: fill
                                                })),
                                        "chart-icon-" + String(index),
                                        undefined
                                      ]));
                      }), icons)), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.true_),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    ])));
}

var make = ChartIconsRow;

export {
  make ,
  
}
/* react Not a pure module */
