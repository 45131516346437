// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as CtemIcon$CTEM from "./CtemIcon.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ModalLogout$CTEM from "./ModalLogout.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import * as SavedWorksContext$CTEM from "../Contexts/SavedWorksContext.bs.js";
import IconButton from "@material-ui/core/IconButton";

function TopBar(Props) {
  var modeOpt = Props.mode;
  var linkTextOpt = Props.linkText;
  var urlPathOpt = Props.urlPath;
  var titleOpt = Props.title;
  var onEditNameOpt = Props.onEditName;
  var rightElement = Props.rightElement;
  var mode = modeOpt !== undefined ? modeOpt : /* Default */0;
  var linkText = linkTextOpt !== undefined ? linkTextOpt : "";
  var urlPath = urlPathOpt !== undefined ? urlPathOpt : "";
  var title = titleOpt !== undefined ? titleOpt : "";
  var onEditName = onEditNameOpt !== undefined ? onEditNameOpt : (function (param) {
        
      });
  var match = SavedWorksContext$CTEM.useSavedWorksResults(undefined);
  var fetchData = match.fetchData;
  var match$1 = React.useState(function () {
        return /* Idle */0;
      });
  var setModalMode = match$1[1];
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "topbar-container" + (
                    mode === /* Default */0 ? "" : " topbar-presentation"
                  ),
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), match$1[0] ? React.createElement(ModalLogout$CTEM.make, {
                    onHandleClose: (function (param) {
                        return Curry._1(setModalMode, (function (param) {
                                      return /* Idle */0;
                                    }));
                      }),
                    onConfirm: (function (param) {
                        localStorage.removeItem("token");
                        return ReasonReactRouter.push(UrlPath$CTEM.getPath(UrlPath$CTEM.login));
                      })
                  }) : null, mode ? React.createElement(React.Fragment, undefined, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement(Link, {
                                      className: "align-center-vertical",
                                      color: "inherit",
                                      variant: MaterialUIDataType.TypographyStyle.subtitle1,
                                      children: React.createElement("u", {
                                            onClick: (function (param) {
                                                Curry._1(fetchData, true);
                                                return ReasonReactRouter.push(urlPath);
                                              })
                                          }, linkText)
                                    })),
                            undefined,
                            undefined
                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                            undefined,
                            undefined,
                            undefined,
                            "text-center center-col",
                            Caml_option.some(React.createElement("div", {
                                      className: "file-name-col"
                                    }, React.createElement("h3", undefined, title), mode._0 ? React.createElement("div", {
                                            className: "edit-icon",
                                            onClick: onEditName
                                          }, React.createElement(CtemIcon$CTEM.make, {
                                                iconType: /* Pencil */11,
                                                className: "icon-outline-text-color"
                                              })) : null)),
                            undefined,
                            undefined
                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(rightElement !== undefined ? Caml_option.valFromOption(rightElement) : null),
                            undefined,
                            undefined
                          ]))) : React.createElement(React.Fragment, undefined, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(8)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined
                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                            undefined,
                            undefined,
                            undefined,
                            "profile-col",
                            Caml_option.some(React.createElement(IconButton, {
                                      onClick: (function (param) {
                                          return Curry._1(setModalMode, (function (param) {
                                                        return /* Logout */1;
                                                      }));
                                        }),
                                      children: React.createElement(CtemIcon$CTEM.make, {
                                            iconType: /* UserProfile */19,
                                            className: "icon-outline-text-color"
                                          })
                                    })),
                            undefined,
                            undefined
                          ]))));
}

var make = TopBar;

export {
  make ,
  
}
/* react Not a pure module */
