// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ChartIconsRow$CTEM from "../../ChartIconsRow.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as LAICalculation$CTEM from "../LAICalculation.bs.js";
import * as StackedBarChart$CTEM from "../../StackedBarChart.bs.js";
import Grid from "@material-ui/core/Grid";

function CostComparison(Props) {
  var data = Props.data;
  var currencyCode = Props.currencyCode;
  var drugCostOld = [{
      title: "drugCost",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.totalDrugCost(true, data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#F4BE90",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var drugCostNew = [{
      title: "drugCost",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.totalDrugCost(false, data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "hsl(28, 83%, 53%)",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var drugCostChart_icons = {
    hd: /* DrugCircle */40,
    tl: /* [] */0
  };
  var drugCostChart = {
    topic: "Drug costs",
    subTitle: "",
    oldData: drugCostOld,
    newData: drugCostNew,
    unitName: currencyCode,
    tooltipElement: undefined,
    icons: drugCostChart_icons
  };
  var phsicianCostOld = [{
      title: "phsicianCost",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.oralErAndPhysicianCostCurrent(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#B6DEF5",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var phsicianCostNew = [{
      title: "phsicianCost",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: (LAICalculation$CTEM.totalCostErPhysician(data) + LAICalculation$CTEM.laiAdministrationCosts(data)).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#6DBDEB",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var phsicianCostChart_icons = {
    hd: /* DoctorCircle */39,
    tl: /* [] */0
  };
  var phsicianCostChart = {
    topic: "Physician visits and Admin costs",
    subTitle: "",
    oldData: phsicianCostOld,
    newData: phsicianCostNew,
    unitName: currencyCode,
    tooltipElement: undefined,
    icons: phsicianCostChart_icons
  };
  var hospitalizationCostOld = [{
      title: "hospitalizationCost",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.totalCostOfHospitalizationCurrent(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#91BFD9",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var hospitalizationCostNew = [{
      title: "hospitalizationCost",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.totalCostOfHospitalizationNew(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#2380B4",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var hospitalizationCostChart_icons = {
    hd: /* HospCircle */43,
    tl: /* [] */0
  };
  var hospitalizationCostChart = {
    topic: "Hospitalization costs",
    subTitle: "",
    oldData: hospitalizationCostOld,
    newData: hospitalizationCostNew,
    unitName: currencyCode,
    tooltipElement: undefined,
    icons: hospitalizationCostChart_icons
  };
  var indirectCostOld = [{
      title: "indirectCost",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.totalIndirectCostsCurrent(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#89A7B7",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var indirectCostNew = [{
      title: "indirectCost",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.totalIndirectCostsNew(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#144F70",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var indirectCostChart_icons = {
    hd: /* GearCircle */41,
    tl: /* [] */0
  };
  var indirectCostChart = {
    topic: "Indirect costs",
    subTitle: "",
    oldData: indirectCostOld,
    newData: indirectCostNew,
    unitName: currencyCode,
    tooltipElement: undefined,
    icons: indirectCostChart_icons
  };
  var allCharts_1 = {
    hd: hospitalizationCostChart,
    tl: {
      hd: phsicianCostChart,
      tl: {
        hd: indirectCostChart,
        tl: /* [] */0
      }
    }
  };
  var allCharts = {
    hd: drugCostChart,
    tl: allCharts_1
  };
  var maxValue = [0];
  List.map((function (chart) {
          if (Caml_array.get(maxValue, 0) < Caml_format.caml_float_of_string(Caml_array.get(chart.oldData, 0).value)) {
            Caml_array.set(maxValue, 0, Caml_format.caml_float_of_string(Caml_array.get(chart.oldData, 0).value));
          }
          if (Caml_array.get(maxValue, 0) < Caml_format.caml_float_of_string(Caml_array.get(chart.newData, 0).value)) {
            return Caml_array.set(maxValue, 0, Caml_format.caml_float_of_string(Caml_array.get(chart.oldData, 0).value));
          }
          
        }), allCharts);
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "height-100",
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), $$Array.of_list(List.mapi((function (index, chartData) {
                        return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "height-70",
                                        Caml_option.some(React.createElement(StackedBarChart$CTEM.make, {
                                                  verticalChartData: chartData,
                                                  layout: "vertical",
                                                  height: "75%",
                                                  padding: 0.1,
                                                  margin: {
                                                    top: 5,
                                                    right: 90,
                                                    bottom: 80,
                                                    left: 80
                                                  }
                                                })),
                                        "cost-comparison-" + String(index),
                                        undefined
                                      ]));
                      }), allCharts)), $$Array.of_list(List.mapi((function (index, chartData) {
                        return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "chart-icons-row text-center",
                                        Caml_option.some(React.createElement(ChartIconsRow$CTEM.make, {
                                                  rowSize: 3,
                                                  icons: chartData.icons,
                                                  fill: Caml_array.get(chartData.newData, 0).color
                                                })),
                                        "chart-icons-row-" + String(index),
                                        undefined
                                      ]));
                      }), allCharts)));
}

var make = CostComparison;

export {
  make ,
  
}
/* react Not a pure module */
