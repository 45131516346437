// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Utils$CTEM from "./Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TopBar$CTEM from "./Components/TopBar.bs.js";
import * as Loading$CTEM from "./Components/Loading.bs.js";
import * as UrlPath$CTEM from "./UrlPath.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as DataTypes$CTEM from "./DataTypes.bs.js";
import * as LoginPage$CTEM from "./Pages/LoginPage.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as RequestUtils$CTEM from "./RequestUtils.bs.js";
import * as DashboardPage$CTEM from "./Pages/DashboardPage.bs.js";
import * as ModelsContext$CTEM from "./Contexts/ModelsContext.bs.js";
import * as ForgotPassPage$CTEM from "./Pages/ForgotPassPage.bs.js";
import * as CreateModelPage$CTEM from "./Pages/CreateModelPage.bs.js";
import * as CountriesContext$CTEM from "./Contexts/CountriesContext.bs.js";
import * as PresentationPage$CTEM from "./Pages/PresentationPage.bs.js";
import * as SavedWorksContext$CTEM from "./Contexts/SavedWorksContext.bs.js";
import * as ModelManagementPage$CTEM from "./Pages/ModelManagementPage.bs.js";
import * as ServiceWorkerLoader$CTEM from "./ServiceWorkerLoader.bs.js";

var setRatio = (function() {
    setTimeout(function(){
      var mainElement = document.getElementById("main");
      var elHeight = mainElement.offsetHeight;
      var elWidth = mainElement.offsetWidth;

      var rootElement = document.getElementById("root");

      var scale;
      scale = Math.min(
        rootElement.offsetWidth / elWidth,    
        rootElement.offsetHeight / elHeight
      );
      mainElement.style.transform = "translate(-50%, -50%) " + "scale(" + scale + ")"; 
      localStorage.setItem('scaleRatio', scale);
      var mainElement = document.getElementById("main");
      window.addEventListener('resize', function(event) {
          var newScale = Math.min(
            rootElement.offsetWidth / elWidth,    
            rootElement.offsetHeight / elHeight
          );
          mainElement.style.transform = "translate(-50%, -50%) " + "scale(" + newScale + ")"; 
          localStorage.setItem('scaleRatio', newScale);
      }, true);

    }, 500);
  });

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SetCountriesResults */0 :
        return {
                countriesResults: action._0,
                modelsResults: state.modelsResults,
                savedWorksResults: state.savedWorksResults
              };
    case /* SetModelsResults */1 :
        return {
                countriesResults: state.countriesResults,
                modelsResults: action._0,
                savedWorksResults: state.savedWorksResults
              };
    case /* SetSavedWorksResults */2 :
        return {
                countriesResults: state.countriesResults,
                modelsResults: state.modelsResults,
                savedWorksResults: action._0
              };
    
  }
}

function App(Props) {
  ServiceWorkerLoader$CTEM.initServiceWorker(undefined);
  var url = ReasonReactRouter.useUrl(undefined, undefined);
  var match = localStorage.getItem("token");
  var isLogined = match !== null;
  var match$1 = React.useReducer(reducer, {
        countriesResults: /* NotLoaded */0,
        modelsResults: /* NotLoaded */0,
        savedWorksResults: /* NotLoaded */0
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var requestModels = function (param) {
    return Utils$CTEM.loadDataIfNone(state.modelsResults, false, (function (param) {
                  Curry._1(dispatch, {
                        TAG: /* SetModelsResults */1,
                        _0: /* Loading */1
                      });
                  RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Get */0, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.modelsAPIPath), (function (json) {
                          var models = DataTypes$CTEM.Decode.modelTemplates(json);
                          return Curry._1(dispatch, {
                                      TAG: /* SetModelsResults */1,
                                      _0: {
                                        TAG: /* LoadSuccess */0,
                                        _0: models
                                      }
                                    });
                        }), (function (json) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetModelsResults */1,
                                      _0: {
                                        TAG: /* LoadFailed */1,
                                        _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                      }
                                    });
                        }));
                  
                }));
  };
  var requestCountries = function (param) {
    return Utils$CTEM.loadDataIfNone(state.countriesResults, false, (function (param) {
                  return Curry._1(dispatch, {
                              TAG: /* SetCountriesResults */0,
                              _0: {
                                TAG: /* LoadSuccess */0,
                                _0: Belt_List.sort(DataTypes$CTEM.mockupCountries, Caml_primitive.caml_string_compare)
                              }
                            });
                }));
  };
  var requestSavedWorks = function (reload) {
    return Utils$CTEM.loadDataIfNone(state.savedWorksResults, reload, (function (param) {
                  Curry._1(dispatch, {
                        TAG: /* SetSavedWorksResults */2,
                        _0: /* Loading */1
                      });
                  RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Get */0, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.savedWorksAPIPath), (function (json) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetSavedWorksResults */2,
                                      _0: {
                                        TAG: /* LoadSuccess */0,
                                        _0: DataTypes$CTEM.Decode.works(json)
                                      }
                                    });
                        }), (function (json) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetSavedWorksResults */2,
                                      _0: {
                                        TAG: /* LoadFailed */1,
                                        _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                                      }
                                    });
                        }));
                  
                }));
  };
  React.useEffect((function () {
          if (isLogined) {
            requestSavedWorks(false);
          } else {
            if (state.savedWorksResults !== /* NotLoaded */0) {
              Curry._1(dispatch, {
                    TAG: /* SetSavedWorksResults */2,
                    _0: /* NotLoaded */0
                  });
            }
            if (state.modelsResults !== /* NotLoaded */0) {
              Curry._1(dispatch, {
                    TAG: /* SetModelsResults */1,
                    _0: /* NotLoaded */0
                  });
            }
            if (state.countriesResults !== /* NotLoaded */0) {
              Curry._1(dispatch, {
                    TAG: /* SetCountriesResults */0,
                    _0: /* NotLoaded */0
                  });
            }
            
          }
          
        }), [isLogined]);
  var presentaionNoDataTopBar = React.createElement(TopBar$CTEM.make, {
        mode: /* Presentation */{
          _0: false
        },
        linkText: "< Back to Home",
        urlPath: UrlPath$CTEM.getPath(UrlPath$CTEM.dashboard),
        title: "Saved work"
      });
  var workNotFound = function (workId) {
    return React.createElement(React.Fragment, undefined, presentaionNoDataTopBar, React.createElement("p", {
                    style: {
                      padding: "100px",
                      textAlign: "start",
                      width: "100%"
                    }
                  }, "Your saved work id " + workId + " does not exist."));
  };
  React.createElement("p", {
        style: {
          padding: "100px",
          textAlign: "start",
          width: "100%"
        }
      }, Utils$CTEM.textWithNewLine(Utils$CTEM.defaultErrorMessage));
  var createWorkComponent = function (workId, addToElement) {
    var works = state.savedWorksResults;
    if (typeof works === "number") {
      if (works === /* NotLoaded */0) {
        return null;
      } else {
        return React.createElement(Loading$CTEM.make, {});
      }
    } else if (works.TAG === /* LoadSuccess */0) {
      return Belt_Option.mapWithDefault(List.find_opt((function (work) {
                        return String(work.id) === workId;
                      }), works._0), workNotFound(workId), Curry.__1(addToElement));
    } else {
      return React.createElement(React.Fragment, undefined, presentaionNoDataTopBar, React.createElement("p", {
                      style: {
                        padding: "100px",
                        textAlign: "start",
                        width: "100%"
                      }
                    }, Utils$CTEM.textWithNewLine(works._0)));
    }
  };
  Curry._1(setRatio, undefined);
  var tmp;
  if (isLogined) {
    var match$2 = url.path;
    var tmp$1;
    var exit = 0;
    if (match$2) {
      switch (match$2.hd) {
        case "create-model" :
            if (match$2.tl) {
              exit = 1;
            } else {
              tmp$1 = React.createElement(CountriesContext$CTEM.Provider.make, CountriesContext$CTEM.Provider.makeProps([
                        state.countriesResults,
                        requestCountries
                      ], React.createElement(CreateModelPage$CTEM.make, {}), undefined));
            }
            break;
        case "presentation" :
            var match$3 = match$2.tl;
            if (match$3) {
              var match$4 = match$3.tl;
              var workId = match$3.hd;
              if (match$4) {
                if (match$4.hd === "edit" && !match$4.tl) {
                  tmp$1 = createWorkComponent(workId, (function (work) {
                          return React.createElement(ModelManagementPage$CTEM.make, {
                                      work: work
                                    });
                        }));
                } else {
                  exit = 1;
                }
              } else {
                tmp$1 = createWorkComponent(workId, (function (work) {
                        return React.createElement(PresentationPage$CTEM.make, {
                                    work: work
                                  });
                      }));
              }
            } else {
              exit = 1;
            }
            break;
        case "saved-works" :
            exit = 1;
            break;
        default:
          exit = 1;
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$1 = React.createElement(DashboardPage$CTEM.make, {});
    }
    tmp = React.createElement(ModelsContext$CTEM.Provider.make, ModelsContext$CTEM.Provider.makeProps([
              state.modelsResults,
              requestModels
            ], React.createElement(SavedWorksContext$CTEM.Provider.make, SavedWorksContext$CTEM.Provider.makeProps({
                      value: state.savedWorksResults,
                      fetchData: requestSavedWorks,
                      updateData: (function (results) {
                          return Curry._1(dispatch, {
                                      TAG: /* SetSavedWorksResults */2,
                                      _0: results
                                    });
                        })
                    }, tmp$1, undefined)), undefined));
  } else {
    var match$5 = url.path;
    var exit$1 = 0;
    if (match$5) {
      var exit$2 = 0;
      switch (match$5.hd) {
        case "forgot-password" :
        case "reset-password" :
            exit$2 = 2;
            break;
        default:
          exit$1 = 1;
      }
      if (exit$2 === 2) {
        if (match$5.tl) {
          exit$1 = 1;
        } else {
          var token = [""];
          var email = [""];
          $$Array.map((function (param) {
                  var paramSplited = param.split("=");
                  if (paramSplited.length === 2) {
                    if (Caml_array.get(paramSplited, 0) === "email") {
                      return Caml_array.set(email, 0, Caml_array.get(paramSplited, 1).replace("%40", "@"));
                    } else {
                      return Caml_array.set(token, 0, Caml_array.get(paramSplited, 1));
                    }
                  }
                  
                }), url.search.split("&"));
          tmp = React.createElement(ForgotPassPage$CTEM.make, {
                email: Caml_array.get(email, 0),
                token: Caml_array.get(token, 0)
              });
        }
      }
      
    } else {
      exit$1 = 1;
    }
    if (exit$1 === 1) {
      tmp = React.createElement(LoginPage$CTEM.make, {});
    }
    
  }
  return React.createElement("main", {
              id: "main"
            }, tmp);
}

var make = App;

export {
  setRatio ,
  reducer ,
  make ,
  
}
/* react Not a pure module */
