// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

var Color = {
  $$default: "default",
  inherit_: "inherit",
  primary: "primary",
  secondary: "secondary"
};

var Edge = {
  start: "start",
  end_: "end",
  false_: false
};

var Size = {
  small: "small",
  medium: "medium"
};

function makeProps(prim, prim$1, prim$2, prim$3, prim$4, prim$5, prim$6, prim$7, prim$8, prim$9, prim$10, prim$11) {
  var tmp = {};
  if (prim !== undefined) {
    tmp.id = Caml_option.valFromOption(prim);
  }
  if (prim$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(prim$1);
  }
  if (prim$2 !== undefined) {
    tmp.edge = Caml_option.valFromOption(prim$2);
  }
  if (prim$3 !== undefined) {
    tmp.color = Caml_option.valFromOption(prim$3);
  }
  if (prim$4 !== undefined) {
    tmp.size = Caml_option.valFromOption(prim$4);
  }
  if (prim$5 !== undefined) {
    tmp.onClick = Caml_option.valFromOption(prim$5);
  }
  if (prim$6 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim$6);
  }
  if (prim$7 !== undefined) {
    tmp.disableFocusRipple = Caml_option.valFromOption(prim$7);
  }
  if (prim$8 !== undefined) {
    tmp.disableRipple = Caml_option.valFromOption(prim$8);
  }
  if (prim$9 !== undefined) {
    tmp.children = Caml_option.valFromOption(prim$9);
  }
  if (prim$10 !== undefined) {
    tmp["aria-describedby"] = Caml_option.valFromOption(prim$10);
  }
  return tmp;
}

export {
  Color ,
  Edge ,
  Size ,
  makeProps ,
  
}
/* No side effect */
