// Generated by ReScript, PLEASE EDIT WITH CARE


var laiManagementTopics = {
  hd: /* LAIModelOverview */0,
  tl: {
    hd: /* TargetedAndMS */1,
    tl: {
      hd: /* CIAndHRU */2,
      tl: {
        hd: /* DirectCosts */3,
        tl: {
          hd: /* IndirectCosts */4,
          tl: /* [] */0
        }
      }
    }
  }
};

function laiManagementTopicToString(topic) {
  switch (topic) {
    case /* LAIModelOverview */0 :
        return "Model Overview";
    case /* TargetedAndMS */1 :
        return "Targeted Schizophrenia Patients & Market Share";
    case /* CIAndHRU */2 :
        return "Clinical Inputs & Healthcare Resource Utilization";
    case /* DirectCosts */3 :
        return "Drug Costs";
    case /* IndirectCosts */4 :
        return "Other Costs";
    
  }
}

function getManagementTopicsFromTemplate(modeltTemplate) {
  if (typeof modeltTemplate === "number") {
    return /* [] */0;
  } else {
    return laiManagementTopics;
  }
}

var slideController = (function(isVertical, currentSlide, startAxis, touchEvent) {
    if (touchEvent.type == "touchstart") {
      if (touchEvent.touches.length > 0) {
        var screenAxis = (isVertical ? touchEvent.touches[0].screenY : touchEvent.touches[0].screenX)
        return screenAxis;
      }
    } else {
      if (touchEvent.changedTouches.length > 0) {
        var endAxis = (isVertical ? touchEvent.changedTouches[0].screenY : touchEvent.changedTouches[0].screenX);
        if (endAxis - startAxis >= 100) {
          return currentSlide - 1;
        } else if (startAxis - endAxis >= 100) {
          return currentSlide + 1;
        }
        return currentSlide;
      }
    }
    return -1;
  });

var drugMSDefault = {
  oralKey: "",
  laiKey: "",
  withoutLAI: {
    title: "",
    value: 0,
    source: ""
  },
  withLAI: {
    title: "",
    value: 0,
    source: ""
  }
};

export {
  drugMSDefault ,
  laiManagementTopics ,
  laiManagementTopicToString ,
  getManagementTopicsFromTemplate ,
  slideController ,
  
}
/* No side effect */
