// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as UrlPath$CTEM from "../../../UrlPath.bs.js";
import * as RequestUtils$CTEM from "../../../RequestUtils.bs.js";

function requestUpdateLAIData(workId, encoder, successAction, failAction) {
  RequestUtils$CTEM.requestJsonResponseToAction(Fetch.RequestInit.make(/* Patch */8, Caml_option.some(RequestUtils$CTEM.buildHeader(undefined)), Caml_option.some(JSON.stringify(encoder)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined), UrlPath$CTEM.internalUrl(undefined, UrlPath$CTEM.fileIdPath(String(workId))), successAction, failAction);
  
}

export {
  requestUpdateLAIData ,
  
}
/* RequestUtils-CTEM Not a pure module */
