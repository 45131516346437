// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.js";

function getValueFromReactEventForm($$event) {
  return $$event.target.value;
}

function getIntFromString(stringValue, defaultValue) {
  try {
    return Caml_format.caml_int_of_string(stringValue);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Failure") {
      return defaultValue;
    }
    throw exn;
  }
}

function strIsFloat(str) {
  return !isNaN(Number(str));
}

function getFloatWithDigit(digitOpt, value) {
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var valueSplited = value.toString().split(".");
  var match = valueSplited.length;
  if (digit === 0) {
    return Caml_array.get(valueSplited, 0);
  }
  if (match <= 1) {
    if (match === 1) {
      return Caml_array.get(valueSplited, 0) + ".00";
    } else {
      return "0.00";
    }
  }
  var length = Caml_array.get(valueSplited, 1).length;
  if (length >= digit) {
    return Caml_array.get(valueSplited, 0) + ("." + Caml_array.get(valueSplited, 1).slice(0, digit));
  } else if (length <= 0) {
    return Caml_array.get(valueSplited, 0) + ".00";
  } else {
    return Caml_array.get(valueSplited, 0) + ("." + (Caml_array.get(valueSplited, 1) + "0"));
  }
}

function shortCurrencyWithAlphabet(value, length) {
  var backNumber = function (valueSliced) {
    return "." + value.slice(valueSliced.length, valueSliced.length + 2 | 0);
  };
  var match;
  if (length >= 13) {
    var valueSliced = value.slice(0, length - 12 | 0);
    match = [
      valueSliced + backNumber(valueSliced),
      "T"
    ];
  } else if (length >= 10 && length < 13) {
    var valueSliced$1 = value.slice(0, length - 9 | 0);
    match = [
      valueSliced$1 + backNumber(valueSliced$1),
      "B"
    ];
  } else if (length >= 7 && length < 10) {
    var valueSliced$2 = value.slice(0, length - 6 | 0);
    match = [
      valueSliced$2 + backNumber(valueSliced$2),
      "M"
    ];
  } else if (length >= 4 && length < 7) {
    var valueSliced$3 = value.slice(0, length - 3 | 0);
    match = [
      valueSliced$3 + backNumber(valueSliced$3),
      "K"
    ];
  } else {
    match = [
      value,
      ""
    ];
  }
  return match[0] + match[1];
}

function getFloatFromString(digitOpt, stringValue, defaultValue) {
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var value;
  try {
    value = Caml_format.caml_float_of_string(stringValue);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === "Failure") {
      return defaultValue;
    }
    throw exn;
  }
  return Caml_format.caml_float_of_string(getFloatWithDigit(digit, value));
}

function validStringFromStringCurrency(maxDigitOpt, value) {
  var maxDigit = maxDigitOpt !== undefined ? maxDigitOpt : 2;
  var valueSplited = value.split(".");
  var x = valueSplited.length;
  if (x > 1) {
    return Caml_array.get(valueSplited, 0) + ("." + Caml_array.get(valueSplited, 1).slice(0, Caml_array.get(valueSplited, 1).length > 2 ? Caml_array.get(valueSplited, 1).length : maxDigit));
  } else if (x === 1) {
    return Caml_array.get(valueSplited, 0);
  } else {
    return "";
  }
}

var getNumberFormat = (function(number) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(number).slice(1, formatter.format(number).length) + "";
  });

function isStringEmpty(str) {
  if (str.length <= 0) {
    return true;
  } else {
    return Belt_Option.isSome(Caml_option.null_to_opt(str.match(new RegExp("^\\W+$", "g"))));
  }
}

function shortNumberFromString(value) {
  var intNumber = value.split(".");
  var length = Caml_array.get(intNumber, 0).length;
  if (length < 4) {
    return value;
  }
  var intNumber$1 = Caml_array.get(intNumber, 0).split(".");
  return shortCurrencyWithAlphabet(Caml_array.get(intNumber$1, 0), length);
}

var getKeysFromObject = (function(obj) {
    return Object.keys(obj);
  });

function loadDataIfNone(results, reload, handle) {
  if (typeof results === "number") {
    if (results === /* NotLoaded */0 || reload) {
      return Curry._1(handle, undefined);
    } else {
      console.log("Loading");
      return ;
    }
  } else if (results.TAG === /* LoadSuccess */0 && !reload) {
    console.log("Already loaded data");
    return ;
  } else {
    return Curry._1(handle, undefined);
  }
}

function textWithNewLine(text) {
  var match = text.includes("\n");
  var match$1 = text.includes("\\n");
  if (match) {
    if (match$1) {
      return $$Array.of_list({
                  hd: text,
                  tl: /* [] */0
                });
    }
    var textLine = text.split("\n");
    return $$Array.of_list(List.mapi((function (index, t) {
                      return React.createElement(React.Fragment, {
                                  children: null,
                                  key: "text-new-line-" + (String(index) + Caml_array.get(textLine, 0))
                                }, t, React.createElement("br", undefined));
                    }), $$Array.to_list(textLine)));
  }
  if (!match$1) {
    return $$Array.of_list({
                hd: text,
                tl: /* [] */0
              });
  }
  var textLine$1 = text.split("\\n");
  return $$Array.of_list(List.mapi((function (index, t) {
                    return React.createElement(React.Fragment, {
                                children: null,
                                key: "text-new-line-" + (String(index) + Caml_array.get(textLine$1, 0))
                              }, t, React.createElement("br", undefined));
                  }), $$Array.to_list(textLine$1)));
}

function ignoreTextNewLine(text) {
  var result = [""];
  var match = text.includes("\n");
  var match$1 = text.includes("\\n");
  if (match) {
    if (match$1) {
      return text;
    }
    var textLine = text.split("\n");
    List.map((function (t) {
            return Caml_array.set(result, 0, Caml_array.get(result, 0) + t);
          }), $$Array.to_list(textLine));
    return Caml_array.get(result, 0);
  }
  if (!match$1) {
    return text;
  }
  var textLine$1 = text.split("\\n");
  List.map((function (t) {
          return Caml_array.set(result, 0, Caml_array.get(result, 0) + t);
        }), $$Array.to_list(textLine$1));
  return Caml_array.get(result, 0);
}

var refreshPage = (function() {
    location.reload();
  });

var defaultErrorMessage = "\nSomething went wrong. Please try the following steps.\n\n1. Check if you are connected to the internet, and refresh the page.\n\n2. Clear your browser cache, and refresh the page.\n\n3. If the problem still persists, please contact our system admin for further support.\n\n";

export {
  defaultErrorMessage ,
  getValueFromReactEventForm ,
  getIntFromString ,
  strIsFloat ,
  getFloatWithDigit ,
  shortCurrencyWithAlphabet ,
  getFloatFromString ,
  validStringFromStringCurrency ,
  getNumberFormat ,
  isStringEmpty ,
  shortNumberFromString ,
  getKeysFromObject ,
  loadDataIfNone ,
  textWithNewLine ,
  ignoreTextNewLine ,
  refreshPage ,
  
}
/* react Not a pure module */
