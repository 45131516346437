// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as CtemTooltip$CTEM from "../../CtemTooltip.bs.js";
import * as ChartIconsRow$CTEM from "../../ChartIconsRow.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as LAICalculation$CTEM from "../LAICalculation.bs.js";
import * as StackedBarChart$CTEM from "../../StackedBarChart.bs.js";
import Grid from "@material-ui/core/Grid";

function LAIOutcomeOne(Props) {
  var data = Props.data;
  var match = React.useReducer((function (_state, action) {
          return {
                  targetChart: action._0
                };
        }), {
        targetChart: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var notHospNorRelapsedCurrent = [{
      title: "notHospNorRelapsed",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.oralNotHospNorRelapsedCurrent(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#F4BE8F",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var notHospNorRelapsedNew = [{
      title: "notHospNorRelapsed",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: Math.round(LAICalculation$CTEM.oralNotHospNorRelapsedNew(data) + LAICalculation$CTEM.laiNotHospNorRelapsed(data)).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#EA7E21",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var notHospNorRelapsedChart_icons = {
    hd: /* HealthyCircle */45,
    tl: /* [] */0
  };
  var notHospNorRelapsedChart = {
    topic: "Number of patients who are not hospitalized nor relapsed",
    subTitle: "",
    oldData: notHospNorRelapsedCurrent,
    newData: notHospNorRelapsedNew,
    unitName: "",
    tooltipElement: undefined,
    icons: notHospNorRelapsedChart_icons
  };
  var hospPatientCurrent = [{
      title: "hospPatient",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: Math.round(LAICalculation$CTEM.oralHospitalizedCurrent(data)).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#BEE0F4",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var hospPatientNew = [{
      title: "hospPatient",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: Math.round(LAICalculation$CTEM.oralHospitalizedNew(data) + LAICalculation$CTEM.laiHospitalized(data)).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#92CCED",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var hospPatientChart_tooltipElement = Caml_option.some(React.createElement("div", {
            className: "restore-component chart-tooltip"
          }, React.createElement(CtemTooltip$CTEM.make, {
                dynamicPosition: false,
                tooltipClassName: "tooltip-infos-top text-center",
                onCancel: (function (param) {
                    return Curry._1(dispatch, /* SelectChart */{
                                _0: undefined
                              });
                  }),
                children: null
              }, React.createElement("p", undefined, React.createElement("b", undefined, "Number of hospitalized but not relapsed patients")), React.createElement("b", undefined, Math.round(LAICalculation$CTEM.oralRelapseOtherReasonsCurrent(data)).toString()), " vs ", React.createElement("b", undefined, Math.round(LAICalculation$CTEM.oralRelapseOtherReasonsNew(data) + LAICalculation$CTEM.laiRelapseOtherReasons(data)).toString()))));
  var hospPatientChart_icons = {
    hd: /* HospCircle */43,
    tl: /* [] */0
  };
  var hospPatientChart = {
    topic: "Number of hospitalized patients",
    subTitle: "(Including relapsed and non relapsed)",
    oldData: hospPatientCurrent,
    newData: hospPatientNew,
    unitName: "",
    tooltipElement: hospPatientChart_tooltipElement,
    icons: hospPatientChart_icons
  };
  var relapsedPatientCurrent = [{
      title: "relapsedPatient",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: Math.round(LAICalculation$CTEM.oralRelapsedCurrent(data)).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#9DB6C4",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var relapsedPatientNew = [{
      title: "relapsedPatient",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: Math.round(LAICalculation$CTEM.oralRelapsedNew(data) + LAICalculation$CTEM.laiRelapsed(data)).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#5C859C",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var relapsedPatientChart_tooltipElement = Caml_option.some(React.createElement("div", {
            className: "restore-component chart-tooltip"
          }, React.createElement(CtemTooltip$CTEM.make, {
                dynamicPosition: false,
                tooltipClassName: "tooltip-infos-top text-center",
                onCancel: (function (param) {
                    return Curry._1(dispatch, /* SelectChart */{
                                _0: undefined
                              });
                  }),
                children: null
              }, React.createElement("p", undefined, React.createElement("b", undefined, "Number of relapsed patients but not hospitalized")), React.createElement("b", undefined, Math.round(LAICalculation$CTEM.oralRelapsedNotHospCurrent(data)).toString()), " vs ", React.createElement("b", undefined, Math.round(LAICalculation$CTEM.oralRelapsedNotHospNew(data) + LAICalculation$CTEM.laiRelapsedNotHosp(data)).toString()))));
  var relapsedPatientChart_icons = {
    hd: /* RelaspeCircle */44,
    tl: /* [] */0
  };
  var relapsedPatientChart = {
    topic: "Number of relapsed patients",
    subTitle: "(Including hospitalized and non hospitalized)",
    oldData: relapsedPatientCurrent,
    newData: relapsedPatientNew,
    unitName: "",
    tooltipElement: relapsedPatientChart_tooltipElement,
    icons: relapsedPatientChart_icons
  };
  var hospAndRelapsedCurrent = [{
      title: "hospAndRelapsed",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: Math.round(LAICalculation$CTEM.oralHospAndRelapsedCurrent(data)).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#C1DAE8",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var hospAndRelapsedNew = [{
      title: "hospAndRelapsed",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: Math.round(LAICalculation$CTEM.oralHospAndRelapsedNew(data) + LAICalculation$CTEM.laiHospAndRelapsed(data)).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#5FA6CE",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var hospAndRelapsedChart_icons = {
    hd: /* HospCircle */43,
    tl: {
      hd: /* Plus */5,
      tl: {
        hd: /* RelaspeCircle */44,
        tl: /* [] */0
      }
    }
  };
  var hospAndRelapsedChart = {
    topic: "Number of hospitalized\nand relapsed patients",
    subTitle: "",
    oldData: hospAndRelapsedCurrent,
    newData: hospAndRelapsedNew,
    unitName: "",
    tooltipElement: undefined,
    icons: hospAndRelapsedChart_icons
  };
  var allCharts_1 = {
    hd: hospPatientChart,
    tl: {
      hd: relapsedPatientChart,
      tl: {
        hd: hospAndRelapsedChart,
        tl: /* [] */0
      }
    }
  };
  var allCharts = {
    hd: notHospNorRelapsedChart,
    tl: allCharts_1
  };
  var maxValue = [0];
  List.map((function (chart) {
          if (Caml_array.get(maxValue, 0) < Caml_format.caml_float_of_string(Caml_array.get(chart.oldData, 0).value)) {
            Caml_array.set(maxValue, 0, Caml_format.caml_float_of_string(Caml_array.get(chart.oldData, 0).value));
          }
          if (Caml_array.get(maxValue, 0) < Caml_format.caml_float_of_string(Caml_array.get(chart.newData, 0).value)) {
            return Caml_array.set(maxValue, 0, Caml_format.caml_float_of_string(Caml_array.get(chart.oldData, 0).value));
          }
          
        }), allCharts);
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "height-100",
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), $$Array.of_list(List.mapi((function (index, chartData) {
                        var match = state.targetChart;
                        var match$1 = chartData.topic;
                        var match$2 = chartData.tooltipElement;
                        return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "height-70",
                                        Caml_option.some(null),
                                        "outcome-comparison-" + String(index),
                                        undefined
                                      ]), match !== undefined && match$2 !== undefined && match === match$1 ? Caml_option.valFromOption(match$2) : null, React.createElement(StackedBarChart$CTEM.make, {
                                        verticalChartData: chartData,
                                        layout: "vertical",
                                        height: "75%",
                                        padding: 0.1,
                                        margin: {
                                          top: 70,
                                          right: 90,
                                          bottom: 80,
                                          left: 80
                                        },
                                        maxValueOutside: Caml_array.get(maxValue, 0),
                                        onClick: (function (param) {
                                            return Curry._1(dispatch, /* SelectChart */{
                                                        _0: chartData.topic
                                                      });
                                          })
                                      }));
                      }), allCharts)), $$Array.of_list(List.mapi((function (index, chartData) {
                        return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "chart-icons-row text-center",
                                        Caml_option.some(React.createElement(ChartIconsRow$CTEM.make, {
                                                  rowSize: 3,
                                                  icons: chartData.icons,
                                                  fill: Caml_array.get(chartData.newData, 0).color
                                                })),
                                        "chart-icons-row-" + String(index),
                                        undefined
                                      ]));
                      }), allCharts)));
}

var make = LAIOutcomeOne;

export {
  make ,
  
}
/* react Not a pure module */
