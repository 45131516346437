// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TextField2$CTEM from "./MuiComponents/TextField2.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import TextField from "@material-ui/core/TextField";

function InputComponent(Props) {
  var modeOpt = Props.mode;
  var classNameOpt = Props.className;
  var _typeOpt = Props.type;
  var labelOpt = Props.label;
  var placeholderOpt = Props.placeholder;
  var helperTextOpt = Props.helperText;
  var errorOpt = Props.error;
  var fullWidthOpt = Props.fullWidth;
  var sizeOpt = Props.size;
  var value = Props.value;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var defaultValue = Props.defaultValue;
  var mode = modeOpt !== undefined ? modeOpt : /* Default */0;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var _type = _typeOpt !== undefined ? _typeOpt : "text";
  var label = labelOpt !== undefined ? labelOpt : "";
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var helperText = helperTextOpt !== undefined ? helperTextOpt : "";
  var error = errorOpt !== undefined ? errorOpt : false;
  var fullWidth = fullWidthOpt !== undefined ? fullWidthOpt : true;
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  if (mode) {
    return React.createElement(TextField, TextField2$CTEM.makeProps(undefined, "input-modify-data " + className, label, undefined, helperText, Caml_option.some(MaterialUIDataType.Variant.outlined), undefined, undefined, fullWidth, undefined, undefined, defaultValue, value, onChange, onBlur, undefined, disabled, undefined, undefined, error, undefined, undefined, undefined, undefined, undefined, undefined, placeholder, undefined, undefined, size, undefined));
  } else {
    return React.createElement(TextField, TextField2$CTEM.makeProps(undefined, className, label, undefined, helperText, undefined, undefined, undefined, fullWidth, undefined, undefined, defaultValue, value, onChange, onBlur, _type, disabled, undefined, undefined, error, undefined, {
                    shrink: true
                  }, undefined, undefined, undefined, undefined, placeholder, undefined, undefined, undefined, undefined));
  }
}

var make = InputComponent;

export {
  make ,
  
}
/* react Not a pure module */
