// Generated by ReScript, PLEASE EDIT WITH CARE


var presentation = "presentation";

function editWorkPath(id) {
  return "" + presentation + "/" + id + "/edit";
}

function presentWorkPath(id) {
  return "" + presentation + "/" + id;
}

function getPath(path) {
  return "/" + path;
}

var v1 = "/v1";

var v2 = "/v2";

function internalUrl(isV2Opt, endPoint) {
  var isV2 = isV2Opt !== undefined ? isV2Opt : true;
  var server = (`${API_SERVER}`);
  return server + ((
            isV2 ? v2 : v1
          ) + endPoint);
}

function savedWorkAPIPath(id) {
  return "/models/" + (id + "/");
}

function createWorkAPIPath(mid, countryCode) {
  return "/models/create/" + (mid + ("/" + (countryCode + "/?marketsharezero=true")));
}

function masterTemplateAPIPath(mid, countryCode) {
  return "/models/master/" + (mid + ("/" + (countryCode + "/")));
}

function fileIdPath(id) {
  return "/models/" + (id + "/");
}

var dashboard = "saved-works";

var creatModel = "create-model";

var input = "input";

var forgotPassword = "forgot-password";

var login = "login";

var loginAPIPath = "/accounts/login/";

var resetPasswordAPIPath = "/accounts/reset-password/";

var confirmResetPasswordAPIPath = "/accounts/confirm-reset-password/";

var savedWorksAPIPath = "/models/saved/";

var modelsAPIPath = "/models/templates/";

export {
  dashboard ,
  presentation ,
  creatModel ,
  input ,
  forgotPassword ,
  login ,
  editWorkPath ,
  presentWorkPath ,
  getPath ,
  v1 ,
  v2 ,
  internalUrl ,
  loginAPIPath ,
  resetPasswordAPIPath ,
  confirmResetPasswordAPIPath ,
  savedWorksAPIPath ,
  savedWorkAPIPath ,
  modelsAPIPath ,
  createWorkAPIPath ,
  masterTemplateAPIPath ,
  fileIdPath ,
  
}
/* No side effect */
