// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as InputComponent$CTEM from "./InputComponent.bs.js";

function FloatInput(Props) {
  var value = Props.value;
  var onValueUpdate = Props.onValueUpdate;
  var onTextUpdate = Props.onTextUpdate;
  var currency = Props.currency;
  var className = Props.className;
  var labelOpt = Props.label;
  var placeholderOpt = Props.placeholder;
  var fullWidthOpt = Props.fullWidth;
  var sizeOpt = Props.size;
  var disabledOpt = Props.disabled;
  var digitOpt = Props.digit;
  var errorOpt = Props.error;
  var label = labelOpt !== undefined ? labelOpt : "";
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var fullWidth = fullWidthOpt !== undefined ? fullWidthOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var digit = digitOpt !== undefined ? digitOpt : 2;
  var error = errorOpt !== undefined ? errorOpt : false;
  var match = React.useState(function () {
        return Utils$CTEM.getFloatWithDigit(digit, value);
      });
  var setValueText = match[1];
  var valueText = match[0];
  var isError = !Utils$CTEM.strIsFloat(valueText) || error;
  var shouldUpdateValue = function (e) {
    var valueText = Utils$CTEM.getFloatWithDigit(digit, Utils$CTEM.getValueFromReactEventForm(e));
    Belt_Option.map(onValueUpdate, (function (f) {
            var valueFloat = Number(valueText);
            if (!isNaN(valueFloat)) {
              return Curry._1(f, valueFloat);
            }
            
          }));
    if (Utils$CTEM.strIsFloat(valueText)) {
      return Curry._1(setValueText, (function (param) {
                    return valueText;
                  }));
    }
    
  };
  var handleTextChange = function (e) {
    var valueTextEvent = Utils$CTEM.getValueFromReactEventForm(e);
    Curry._1(setValueText, (function (param) {
            return valueTextEvent;
          }));
    Belt_Option.map(onTextUpdate, (function (f) {
            return Curry._1(f, valueTextEvent);
          }));
    
  };
  React.useEffect((function () {
          return (function (param) {
                    return Curry._1(setValueText, (function (param) {
                                  return Utils$CTEM.getFloatWithDigit(digit, value);
                                }));
                  });
        }), []);
  React.useEffect((function () {
          Curry._1(setValueText, (function (param) {
                  return Utils$CTEM.getFloatWithDigit(digit, value);
                }));
          
        }), [value]);
  var tmp = {
    mode: /* ModifyData */1,
    label: label,
    placeholder: placeholder,
    error: isError,
    fullWidth: fullWidth,
    size: size,
    value: valueText,
    disabled: disabled,
    onChange: handleTextChange,
    onBlur: shouldUpdateValue
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(InputComponent$CTEM.make, tmp), currency !== undefined ? React.createElement("p", undefined, " " + Caml_option.valFromOption(currency)) : null);
}

var make = FloatInput;

export {
  make ,
  
}
/* react Not a pure module */
