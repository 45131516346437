// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TopBar$CTEM from "../Components/TopBar.bs.js";
import * as UrlPath$CTEM from "../UrlPath.bs.js";
import * as WorkBox$CTEM from "../Components/WorkBox.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";
import * as SavedWorksContext$CTEM from "../Contexts/SavedWorksContext.bs.js";
import CircularProgress from "@material-ui/core/CircularProgress";

var setPreviewImage = (function() {
    setTimeout(function(){ 
      var imageContainer = document.getElementsByClassName("workbox-image");
      var previewImage = document.getElementsByClassName("section-container");
      var imageBoxs = document.getElementsByClassName("presentation-container");

      if (imageContainer[0] && previewImage[0]) {
        var ratioY = (imageContainer[0].clientHeight / previewImage[0].clientHeight) * 100;
        for (var i = 0; i < imageBoxs.length; i++) {
          imageBoxs[i].style.transform = "scale(" + ratioY / 100 + ")";
          imageBoxs[i].style.left = 
            (1024 * (-ratioY / 100)) + 
            (1024 * (-ratioY / 100) / 2) + (-69) + "px";
          imageBoxs[i].style.top = 
            (768 * (-ratioY / 100)) + 
            (768 * (-ratioY / 100) / 2) + (-28) + "px";
          //imageBoxs[i].style.left = "calc(-50%" + " - " + (previewImage[0].clientWidth * ratioY / 100) / 2  + "px)";
          imageBoxs[i].style.opacity = "1";
        }
      }
    }, 0);
  });

function DashboardPage(Props) {
  var match = SavedWorksContext$CTEM.useSavedWorksResults(undefined);
  var fetchData = match.fetchData;
  var savedWorksResult = match.value;
  React.useEffect((function () {
          Curry._1(fetchData, false);
          
        }), []);
  var gridWorkEl = function (savedWork, key) {
    var onClick = function (param) {
      return ReasonReactRouter.push(Belt_Option.mapWithDefault(savedWork, UrlPath$CTEM.creatModel, (function (work) {
                        return UrlPath$CTEM.presentWorkPath(work.id);
                      })));
    };
    var tmp = {
      onClick: onClick
    };
    if (savedWork !== undefined) {
      tmp.savedWork = Caml_option.valFromOption(savedWork);
    }
    return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                    undefined,
                    Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    Caml_option.some(React.createElement(WorkBox$CTEM.make, tmp)),
                    key,
                    undefined
                  ]));
  };
  var tmp;
  tmp = typeof savedWorksResult === "number" ? (
      savedWorksResult === /* NotLoaded */0 ? null : React.createElement("div", {
              className: "loading-wrapper_expand"
            }, React.createElement(CircularProgress, {
                  color: MaterialUIDataType.MainWithInheritColor.inherit_
                }))
    ) : (
      savedWorksResult.TAG === /* LoadSuccess */0 ? React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), gridWorkEl(undefined, "add-new-work"), $$Array.of_list(Belt_List.mapWithIndex(savedWorksResult._0, (function (idx, savedWork) {
                        return gridWorkEl(savedWork, "saved-works-" + idx + "-" + savedWork.id.toString());
                      }))), Curry._1(setPreviewImage, undefined)) : React.createElement("p", {
              style: {
                marginTop: "3rem",
                textAlign: "start",
                width: "100%"
              }
            }, Utils$CTEM.textWithNewLine(savedWorksResult._0))
    );
  return React.createElement("div", {
              className: "dashboard-page"
            }, React.createElement(TopBar$CTEM.make, {
                  mode: /* Default */0
                }), React.createElement("div", {
                  className: "homepage-content"
                }, React.createElement("h1", {
                      className: "font-weight-light"
                    }, "Your Saved Work"), tmp));
}

var make = DashboardPage;

export {
  setPreviewImage ,
  make ,
  
}
/* react Not a pure module */
