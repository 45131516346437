// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loading(Props) {
  return React.createElement("div", {
              className: "loading-wrapper_expand"
            }, React.createElement(CircularProgress, {
                  color: MaterialUIDataType.MainWithInheritColor.inherit_
                }));
}

var make = Loading;

export {
  make ,
  
}
/* react Not a pure module */
