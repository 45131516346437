// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as CtemIcon$CTEM from "./CtemIcon.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ReasonReactRouter from "reason-react/lib/es6/src/ReasonReactRouter.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";
import SkipNext from "@material-ui/icons/SkipNext";
import IconButton from "@material-ui/core/IconButton";

function BottomBar(Props) {
  var currentSlideOpt = Props.currentSlide;
  var lastSlideOpt = Props.lastSlide;
  var goToEditPage = Props.goToEditPage;
  var onNextSlide = Props.onNextSlide;
  var currentSlide = currentSlideOpt !== undefined ? currentSlideOpt : 1;
  var lastSlide = lastSlideOpt !== undefined ? lastSlideOpt : 2;
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "bottom-container",
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "col col-left",
                      Caml_option.some(null),
                      undefined,
                      undefined
                    ]), React.createElement(IconButton, {
                      onClick: (function (param) {
                          return ReasonReactRouter.replace("/");
                        }),
                      children: React.createElement(CtemIcon$CTEM.make, {
                            iconType: /* Home */21,
                            className: "icon-outline-text-color"
                          })
                    }), React.createElement(IconButton, {
                      onClick: goToEditPage,
                      children: React.createElement(CtemIcon$CTEM.make, {
                            iconType: /* Pencil */11,
                            className: "icon-outline-text-color"
                          })
                    })), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                      undefined,
                      undefined,
                      undefined,
                      "col col-right",
                      Caml_option.some(null),
                      undefined,
                      undefined
                    ]), React.createElement("div", {
                      className: "slide-controler" + (
                        currentSlide < 1 ? " disabled" : ""
                      )
                    }, React.createElement(IconButton, {
                          className: "icon-back-to-first",
                          onClick: (function (param) {
                              return Curry._1(onNextSlide, 0);
                            }),
                          children: React.createElement(SkipNext, {})
                        }), React.createElement(IconButton, {
                          onClick: (function (param) {
                              return Curry._1(onNextSlide, currentSlide - 1 | 0);
                            }),
                          children: React.createElement(CtemIcon$CTEM.make, {
                                iconType: /* Next */1
                              })
                        })), React.createElement("div", {
                      className: "slide-controler" + (
                        currentSlide === (lastSlide - 1 | 0) ? " disabled" : ""
                      )
                    }, React.createElement(IconButton, {
                          onClick: (function (param) {
                              return Curry._1(onNextSlide, currentSlide + 1 | 0);
                            }),
                          children: React.createElement(CtemIcon$CTEM.make, {
                                iconType: /* Next */1
                              })
                        }))));
}

var make = BottomBar;

export {
  make ,
  
}
/* react Not a pure module */
