// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Section(Props) {
  var classNameOpt = Props.className;
  var marginOpt = Props.margin;
  var paddingOpt = Props.padding;
  var backgroundOpt = Props.background;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var margin = marginOpt !== undefined ? marginOpt : "0px";
  var padding = paddingOpt !== undefined ? paddingOpt : "0px";
  var background = backgroundOpt !== undefined ? backgroundOpt : "";
  return React.createElement("div", {
              className: "section-container " + className,
              style: {
                background: background,
                margin: margin,
                padding: padding
              }
            }, children);
}

var make = Section;

export {
  make ,
  
}
/* react Not a pure module */
