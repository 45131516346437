// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function initValue_1(param) {
  
}

var initValue = [
  /* NotLoaded */0,
  initValue_1
];

var context = React.createContext(initValue);

function useCountriesResults(param) {
  return React.useContext(context);
}

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var Provider = {
  make: make,
  makeProps: makeProps
};

export {
  initValue ,
  context ,
  useCountriesResults ,
  Provider ,
  
}
/* context Not a pure module */
