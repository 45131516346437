// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ChartIconsRow$CTEM from "../../ChartIconsRow.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as LAICalculation$CTEM from "../LAICalculation.bs.js";
import * as StackedBarChart$CTEM from "../../StackedBarChart.bs.js";
import Grid from "@material-ui/core/Grid";

function LAIOutcomeTwo(Props) {
  var data = Props.data;
  var hospitalizedDaysOld = [{
      title: "hospitalizedDays",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.totalHospitalizedDaysCurrent(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#BEE0F4",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var hospitalizedDaysNew = [{
      title: "hospitalizedDays",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.totalHospitalizedDaysNew(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#92CCED",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var hospitalizedDaysChart_icons = {
    hd: /* CalendarCircle */46,
    tl: /* [] */0
  };
  var hospitalizedDaysChart = {
    topic: "Number of hospitalized days",
    subTitle: "",
    oldData: hospitalizedDaysOld,
    newData: hospitalizedDaysNew,
    unitName: "",
    tooltipElement: undefined,
    icons: hospitalizedDaysChart_icons
  };
  var criminalCasesCurrent = [{
      title: "criminalCases",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.oralIncarcerationCaseCurrent(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#9DB6C4",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var criminalCasesNew = [{
      title: "criminalCases",
      titleWeight: {
        TAG: /* Bold */1,
        _0: 20
      },
      value: LAICalculation$CTEM.totalIncarcerationCaseNew(data).toString(),
      valueWeight: {
        TAG: /* Default */0,
        _0: 15
      },
      unitName: "",
      color: "#5C859C",
      tooltipDisplay: /* InVisible */1,
      overlapTitles: /* [] */0
    }];
  var criminalCasesChart_icons = {
    hd: /* CriminalCircle */42,
    tl: /* [] */0
  };
  var criminalCasesChart = {
    topic: "Number of criminal cases",
    subTitle: "",
    oldData: criminalCasesCurrent,
    newData: criminalCasesNew,
    unitName: "",
    tooltipElement: undefined,
    icons: criminalCasesChart_icons
  };
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "height-100",
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                      undefined,
                      undefined,
                      undefined,
                      "height-80",
                      Caml_option.some(React.createElement(StackedBarChart$CTEM.make, {
                                verticalChartData: hospitalizedDaysChart,
                                layout: "vertical",
                                height: "80%",
                                padding: 0.1,
                                margin: {
                                  top: 10,
                                  right: 90,
                                  bottom: 80,
                                  left: 80
                                }
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                      undefined,
                      undefined,
                      undefined,
                      "height-80",
                      Caml_option.some(React.createElement(StackedBarChart$CTEM.make, {
                                verticalChartData: criminalCasesChart,
                                layout: "vertical",
                                height: "80%",
                                padding: 0.1,
                                margin: {
                                  top: 10,
                                  right: 90,
                                  bottom: 80,
                                  left: 80
                                }
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                      undefined,
                      undefined,
                      undefined,
                      "chart-icons-row text-center",
                      Caml_option.some(React.createElement(ChartIconsRow$CTEM.make, {
                                rowSize: 3,
                                icons: {
                                  hd: /* CalendarCircle */46,
                                  tl: /* [] */0
                                },
                                fill: Caml_array.get(hospitalizedDaysNew, 0).color
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(3)),
                      undefined,
                      undefined,
                      undefined,
                      "chart-icons-row text-center",
                      Caml_option.some(React.createElement(ChartIconsRow$CTEM.make, {
                                rowSize: 3,
                                icons: {
                                  hd: /* CriminalCircle */42,
                                  tl: /* [] */0
                                },
                                fill: Caml_array.get(criminalCasesNew, 0).color
                              })),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(2)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    ])));
}

var make = LAIOutcomeTwo;

export {
  make ,
  
}
/* react Not a pure module */
