// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import Grid from "@material-ui/core/Grid";

function childrenBoxs(titles) {
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "height-80",
                  Caml_option.some($$Array.of_list(List.mapi((function (index, title) {
                                  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                  undefined,
                                                  Caml_option.some(MaterialUIDataType.GridSize.true_),
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  "children-box",
                                                  Caml_option.some(title),
                                                  "children-box-" + String(index),
                                                  undefined
                                                ]));
                                }), titles))),
                  undefined,
                  undefined
                ]));
}

function LAIOverview(Props) {
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  "model-overview",
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-color-1",
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), React.createElement("div", {
                                    className: "line-mapping"
                                  }), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center box-overview-center",
                                        Caml_option.some(React.createElement("div", {
                                                  className: "box-rounded"
                                                }, "Eligible Patient Population")),
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    Caml_option.some(MaterialUIDataType.Justify.center),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100 box-overview-head",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center",
                                        Caml_option.some(React.createElement("div", {
                                                  className: "box-overview box-top-overview box-overview-color2"
                                                }, "Current Environment (No LAI)")),
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(1)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center",
                                        Caml_option.some(React.createElement("div", {
                                                  className: "box-overview box-top-overview box-overview-color1"
                                                }, "New Environment (LAI Introduced)")),
                                        undefined,
                                        undefined
                                      ])))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-color-2",
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    Caml_option.some(MaterialUIDataType.Justify.center),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center",
                                        Caml_option.some(React.createElement("div", {
                                                  className: "box-overview box-overview-color2-light"
                                                }, "Market share with current ORAL treatment mix")),
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(1)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center",
                                        Caml_option.some(React.createElement("div", {
                                                  className: "box-overview box-overview-color1-light"
                                                }, "Market share when LAIs are introduced")),
                                        undefined,
                                        undefined
                                      ])))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-color-1",
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    Caml_option.some(MaterialUIDataType.Justify.center),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center box-overview box-overview-color2-light box-overview-parent-4",
                                        Caml_option.some(childrenBoxs({
                                                  hd: "Length of hospital stay",
                                                  tl: {
                                                    hd: "Relapse rate",
                                                    tl: {
                                                      hd: "Rate of hospitalization",
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })),
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(1)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center box-overview box-overview-color1-light box-overview-parent-4",
                                        Caml_option.some(childrenBoxs({
                                                  hd: "Length of hospital stay",
                                                  tl: {
                                                    hd: "Relapse rate",
                                                    tl: {
                                                      hd: "Rate of hospitalization",
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })),
                                        undefined,
                                        undefined
                                      ])))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      "bg-color-2",
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    Caml_option.some(MaterialUIDataType.Justify.center),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center box-overview box-overview-color2-light box-overview-parent-5",
                                        Caml_option.some(childrenBoxs({
                                                  hd: "Drug costs",
                                                  tl: {
                                                    hd: "Hospital visits & Admin costs",
                                                    tl: {
                                                      hd: "Indirect costs",
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })),
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(1)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center box-overview box-overview-color1-light box-overview-parent-5",
                                        Caml_option.some(childrenBoxs({
                                                  hd: "Drug costs",
                                                  tl: {
                                                    hd: "Hospital visits & Admin costs",
                                                    tl: {
                                                      hd: "Indirect costs",
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })),
                                        undefined,
                                        undefined
                                      ])))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    Caml_option.some(MaterialUIDataType.Justify.center),
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center",
                                        Caml_option.some(React.createElement("div", {
                                                  className: "box-overview box-bottom-overview box-overview-color2"
                                                }, "Total cost with current ORALs treatment mix")),
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(1)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(5)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center",
                                        Caml_option.some(React.createElement("div", {
                                                  className: "box-overview box-bottom-overview box-overview-color1"
                                                }, "Total costs when LAIs are introduced")),
                                        undefined,
                                        undefined
                                      ])))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    "height-100",
                                    Caml_option.some(null),
                                    undefined,
                                    undefined
                                  ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        "text-center box-overview-center",
                                        Caml_option.some(React.createElement("div", {
                                                  className: "box-rounded",
                                                  style: {
                                                    marginTop: "10px"
                                                  }
                                                }, "Differences")),
                                        undefined,
                                        undefined
                                      ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                        undefined,
                                        Caml_option.some(MaterialUIDataType.GridSize.size(4)),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])))),
                      undefined,
                      undefined
                    ])));
}

var make = LAIOverview;

export {
  childrenBoxs ,
  make ,
  
}
/* react Not a pure module */
