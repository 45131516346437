// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";

var setScale = (function() {
    setTimeout(function(){
      var scale = localStorage.getItem('scaleRatio');
      if (scale == null) {
        scale = 1;
      }
      var modals = document.getElementsByClassName("modal-content");
      if (modals) {
        for (var i = 0; i < modals.length; i++) {
          modals[i].style.transform = "scale("+ scale +")";
        }
      }
    }, 10);
  });

function ModalItemGroup(Props) {
  var modalItems = Props.modalItems;
  var onCancel = Props.onCancel;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "modal-background",
                  onClick: onCancel
                }), React.createElement("div", {
                  className: "modal-content"
                }, $$Array.of_list(List.mapi((function (index, modalItem) {
                            return React.createElement("div", {
                                        key: "modal-item-" + String(index),
                                        className: "modal-item",
                                        onClick: (function (e) {
                                            e.stopPropagation();
                                            return Curry._1(modalItem.onClick, e);
                                          })
                                      }, React.createElement("p", {
                                            style: {
                                              color: modalItem.color
                                            }
                                          }, modalItem.title));
                          }), modalItems))));
}

var make = ModalItemGroup;

export {
  setScale ,
  make ,
  
}
/* react Not a pure module */
