// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LAIATAndMS$CTEM from "./LAIATAndMS.bs.js";
import * as LAICIAndHRU$CTEM from "./LAICIAndHRU.bs.js";
import * as LAIOverview$CTEM from "./LAIOverview.bs.js";
import * as LAIDirectCosts$CTEM from "./LAIDirectCosts.bs.js";
import * as LAIIndirectCosts$CTEM from "./LAIIndirectCosts.bs.js";

function LAIManagementWrapper(Props) {
  var workId = Props.workId;
  var currency = Props.currency;
  var sectionLAI = Props.sectionLAI;
  var defaultData = Props.defaultData;
  var currentData = Props.currentData;
  switch (sectionLAI) {
    case /* LAIModelOverview */0 :
        return React.createElement(LAIOverview$CTEM.make, {});
    case /* TargetedAndMS */1 :
        return React.createElement(LAIATAndMS$CTEM.make, {
                    workId: workId,
                    defaultData: defaultData,
                    currentData: currentData
                  });
    case /* CIAndHRU */2 :
        return React.createElement(LAICIAndHRU$CTEM.make, {
                    workId: workId,
                    defaultData: defaultData,
                    currentData: currentData
                  });
    case /* DirectCosts */3 :
        return React.createElement(LAIDirectCosts$CTEM.make, {
                    workId: workId,
                    currency: currency,
                    drugs: currentData.drugs,
                    defaultDrugs: defaultData.drugs
                  });
    case /* IndirectCosts */4 :
        return React.createElement(LAIIndirectCosts$CTEM.make, {
                    workId: workId,
                    currency: currency,
                    defaultData: defaultData,
                    currentData: currentData.indirectCosts
                  });
    
  }
}

var make = LAIManagementWrapper;

export {
  make ,
  
}
/* react Not a pure module */
