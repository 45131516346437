// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils$CTEM from "../Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as ModalDialog$CTEM from "./ModalDialog.bs.js";
import * as MaterialUI_Button from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Button.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as MaterialUI_TextField from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_TextField.bs.js";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

function ModalRename(Props) {
  var name = Props.name;
  var onHandleClose = Props.onHandleClose;
  var onUpdate = Props.onUpdate;
  var loadingStateOpt = Props.loadingState;
  var loadingState = loadingStateOpt !== undefined ? loadingStateOpt : /* NotLoaded */0;
  var match = React.useState(function () {
        return name;
      });
  var setFilename = match[1];
  var newFilename = match[0];
  return React.createElement(ModalDialog$CTEM.make, {
              className: "modal-custom",
              onHandleClose: onHandleClose,
              children: React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(Caml_option.some(MaterialUIDataType.FlexDirection.column), 3, undefined), [
                        undefined,
                        undefined,
                        Caml_option.some(MaterialUIDataType.AlignItems.center),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        Caml_option.some(null),
                        undefined,
                        undefined
                      ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement("p", undefined, "Type in a new file name and tab Save")),
                            undefined,
                            undefined
                          ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement(TextField, MaterialUI_TextField.makeProps(undefined, undefined, undefined, undefined, undefined, Caml_option.some(MaterialUIDataType.Variant.outlined), undefined, undefined, undefined, undefined, undefined, undefined, newFilename, (function (e) {
                                            return Curry._1(setFilename, (function (param) {
                                                          return Utils$CTEM.getValueFromReactEventForm(e);
                                                        }));
                                          }), undefined, undefined, undefined, undefined, Utils$CTEM.isStringEmpty(newFilename), undefined, undefined, undefined, undefined, undefined, undefined, name, undefined, undefined, undefined, undefined))),
                            undefined,
                            undefined
                          ])), loadingState === /* Loading */1 ? React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement(CircularProgress, {
                                        color: MaterialUIDataType.MainWithInheritColor.inherit_
                                      })),
                              undefined,
                              undefined
                            ])) : null, React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                            undefined,
                            Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Caml_option.some(React.createElement(Button, {
                                      children: "Save",
                                      className: "btn-solid-color1",
                                      variant: MaterialUI_Button.Variant.contained,
                                      onClick: (function (param) {
                                          return Curry._1(onUpdate, newFilename);
                                        })
                                    })),
                            undefined,
                            undefined
                          ])))
            });
}

var make = ModalRename;

export {
  make ,
  
}
/* react Not a pure module */
