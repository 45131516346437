// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as DataTypes$CTEM from "../../../../DataTypes.bs.js";
import * as EncodeLAI$CTEM from "../EncodeLAI.bs.js";
import * as MaterialUI_Grid from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_Grid.bs.js";
import * as LAIInputForm$CTEM from "./LAIInputForm.bs.js";
import * as RequestUtils$CTEM from "../../../../RequestUtils.bs.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as RequetLAIUtils$CTEM from "../RequetLAIUtils.bs.js";
import * as SavedWorkContext$CTEM from "../../../../Contexts/SavedWorkContext.bs.js";
import Grid from "@material-ui/core/Grid";

function LAIIndirectCosts(Props) {
  var workId = Props.workId;
  var currency = Props.currency;
  var defaultData = Props.defaultData;
  var currentData = Props.currentData;
  var match = SavedWorkContext$CTEM.useSavedWorkResults(undefined);
  var updateData = match.updateData;
  var match$1 = React.useReducer((function (state, action) {
          if (action.TAG === /* RequestState */0) {
            return {
                    indirectCosts: state.indirectCosts,
                    loadingData: action._0
                  };
          } else {
            return {
                    indirectCosts: action._0,
                    loadingData: state.loadingData
                  };
          }
        }), {
        indirectCosts: currentData,
        loadingData: false
      });
  var dispatch = match$1[1];
  var state = match$1[0];
  var updateLaiData = function (otherCosts) {
    return RequetLAIUtils$CTEM.requestUpdateLAIData(workId, EncodeLAI$CTEM.otherCosts(otherCosts), (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadSuccess */0,
                        _0: DataTypes$CTEM.Decode.work(json)
                      });
                  return Curry._1(dispatch, {
                              TAG: /* RequestState */0,
                              _0: false
                            });
                }), (function (json) {
                  Curry._1(updateData, {
                        TAG: /* LoadFailed */1,
                        _0: RequestUtils$CTEM.getResponseMsgFromJson(json)
                      });
                  return Curry._1(dispatch, {
                              TAG: /* RequestState */0,
                              _0: false
                            });
                }));
  };
  React.useEffect((function () {
          if (state.indirectCosts !== currentData) {
            Curry._1(dispatch, {
                  TAG: /* UpdateIndirectCosts */1,
                  _0: currentData
                });
          }
          
        }), [currentData]);
  var tooltipLeftClass = "tooltip-infos-left";
  var inputDisabled = state.loadingData;
  return React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  Caml_option.some(null),
                  undefined,
                  undefined
                ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    Caml_option.some(React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                                                  undefined,
                                                  Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  Caml_option.some(null),
                                                  undefined,
                                                  undefined
                                                ]), React.createElement("h3", undefined, "Other Medical Costs"), React.createElement(LAIInputForm$CTEM.make, {
                                                  inputMode: {
                                                    TAG: /* FloatInput */1,
                                                    _0: state.indirectCosts.otherMedicalCost.hospitalizationCost.value
                                                  },
                                                  title: state.indirectCosts.otherMedicalCost.hospitalizationCost.title,
                                                  unit: currency.code,
                                                  inputDisabled: inputDisabled,
                                                  onValueUpdate: (function (value) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.hospitalizationCost;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: {
                                                                      title: init$2.title,
                                                                      value: value,
                                                                      source: init$2.source
                                                                    },
                                                                    erAndPhysicianVisitCost: init$1.erAndPhysicianVisitCost,
                                                                    erAndPhysicianVisitPP1M: init$1.erAndPhysicianVisitPP1M,
                                                                    erAndPhysicianVisitPP3M: init$1.erAndPhysicianVisitPP3M,
                                                                    costPerAdministrationOfLAI: init$1.costPerAdministrationOfLAI
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    }),
                                                  infoMode: state.indirectCosts.otherMedicalCost.hospitalizationCost.value !== defaultData.indirectCosts.otherMedicalCost.hospitalizationCost.value ? /* Restore */0 : /* Infos */({
                                                        _0: state.indirectCosts.otherMedicalCost.hospitalizationCost.source
                                                      }),
                                                  onRestore: (function (param) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.hospitalizationCost;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: {
                                                                      title: init$2.title,
                                                                      value: defaultData.indirectCosts.otherMedicalCost.hospitalizationCost.value,
                                                                      source: init$2.source
                                                                    },
                                                                    erAndPhysicianVisitCost: init$1.erAndPhysicianVisitCost,
                                                                    erAndPhysicianVisitPP1M: init$1.erAndPhysicianVisitPP1M,
                                                                    erAndPhysicianVisitPP3M: init$1.erAndPhysicianVisitPP3M,
                                                                    costPerAdministrationOfLAI: init$1.costPerAdministrationOfLAI
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    })
                                                }), React.createElement(LAIInputForm$CTEM.make, {
                                                  inputMode: {
                                                    TAG: /* FloatInput */1,
                                                    _0: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.value
                                                  },
                                                  title: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.title,
                                                  unit: currency.code,
                                                  inputDisabled: inputDisabled,
                                                  onValueUpdate: (function (value) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: init$1.hospitalizationCost,
                                                                    erAndPhysicianVisitCost: {
                                                                      title: init$2.title,
                                                                      value: value,
                                                                      source: init$2.source
                                                                    },
                                                                    erAndPhysicianVisitPP1M: init$1.erAndPhysicianVisitPP1M,
                                                                    erAndPhysicianVisitPP3M: init$1.erAndPhysicianVisitPP3M,
                                                                    costPerAdministrationOfLAI: init$1.costPerAdministrationOfLAI
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    }),
                                                  infoMode: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.value !== defaultData.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.value ? /* Restore */0 : /* Infos */({
                                                        _0: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.source
                                                      }),
                                                  onRestore: (function (param) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: init$1.hospitalizationCost,
                                                                    erAndPhysicianVisitCost: {
                                                                      title: init$2.title,
                                                                      value: defaultData.indirectCosts.otherMedicalCost.erAndPhysicianVisitCost.value,
                                                                      source: init$2.source
                                                                    },
                                                                    erAndPhysicianVisitPP1M: init$1.erAndPhysicianVisitPP1M,
                                                                    erAndPhysicianVisitPP3M: init$1.erAndPhysicianVisitPP3M,
                                                                    costPerAdministrationOfLAI: init$1.costPerAdministrationOfLAI
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    })
                                                }), React.createElement(LAIInputForm$CTEM.make, {
                                                  inputMode: {
                                                    TAG: /* FloatInput */1,
                                                    _0: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.value
                                                  },
                                                  title: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.title,
                                                  unit: "days",
                                                  inputDisabled: inputDisabled,
                                                  digit: 0,
                                                  onValueUpdate: (function (value) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: init$1.hospitalizationCost,
                                                                    erAndPhysicianVisitCost: init$1.erAndPhysicianVisitCost,
                                                                    erAndPhysicianVisitPP1M: {
                                                                      title: init$2.title,
                                                                      value: value | 0,
                                                                      source: init$2.source
                                                                    },
                                                                    erAndPhysicianVisitPP3M: init$1.erAndPhysicianVisitPP3M,
                                                                    costPerAdministrationOfLAI: init$1.costPerAdministrationOfLAI
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    }),
                                                  infoMode: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.value !== defaultData.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.value ? /* Restore */0 : /* Infos */({
                                                        _0: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.source
                                                      }),
                                                  onRestore: (function (param) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: init$1.hospitalizationCost,
                                                                    erAndPhysicianVisitCost: init$1.erAndPhysicianVisitCost,
                                                                    erAndPhysicianVisitPP1M: {
                                                                      title: init$2.title,
                                                                      value: defaultData.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP1M.value,
                                                                      source: init$2.source
                                                                    },
                                                                    erAndPhysicianVisitPP3M: init$1.erAndPhysicianVisitPP3M,
                                                                    costPerAdministrationOfLAI: init$1.costPerAdministrationOfLAI
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    })
                                                }), React.createElement(LAIInputForm$CTEM.make, {
                                                  inputMode: {
                                                    TAG: /* FloatInput */1,
                                                    _0: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M.value
                                                  },
                                                  title: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M.title,
                                                  unit: "days",
                                                  inputDisabled: inputDisabled,
                                                  digit: 0,
                                                  onValueUpdate: (function (value) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: init$1.hospitalizationCost,
                                                                    erAndPhysicianVisitCost: init$1.erAndPhysicianVisitCost,
                                                                    erAndPhysicianVisitPP1M: init$1.erAndPhysicianVisitPP1M,
                                                                    erAndPhysicianVisitPP3M: {
                                                                      title: init$2.title,
                                                                      value: value | 0,
                                                                      source: init$2.source
                                                                    },
                                                                    costPerAdministrationOfLAI: init$1.costPerAdministrationOfLAI
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    }),
                                                  infoMode: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M.value !== defaultData.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M.value ? /* Restore */0 : /* Infos */({
                                                        _0: state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M.source
                                                      }),
                                                  onRestore: (function (param) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: init$1.hospitalizationCost,
                                                                    erAndPhysicianVisitCost: init$1.erAndPhysicianVisitCost,
                                                                    erAndPhysicianVisitPP1M: init$1.erAndPhysicianVisitPP1M,
                                                                    erAndPhysicianVisitPP3M: {
                                                                      title: init$2.title,
                                                                      value: defaultData.indirectCosts.otherMedicalCost.erAndPhysicianVisitPP3M.value,
                                                                      source: init$2.source
                                                                    },
                                                                    costPerAdministrationOfLAI: init$1.costPerAdministrationOfLAI
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    })
                                                }), React.createElement(LAIInputForm$CTEM.make, {
                                                  inputMode: {
                                                    TAG: /* FloatInput */1,
                                                    _0: state.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI.value
                                                  },
                                                  title: state.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI.title,
                                                  unit: currency.code,
                                                  inputDisabled: inputDisabled,
                                                  onValueUpdate: (function (value) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: init$1.hospitalizationCost,
                                                                    erAndPhysicianVisitCost: init$1.erAndPhysicianVisitCost,
                                                                    erAndPhysicianVisitPP1M: init$1.erAndPhysicianVisitPP1M,
                                                                    erAndPhysicianVisitPP3M: init$1.erAndPhysicianVisitPP3M,
                                                                    costPerAdministrationOfLAI: {
                                                                      title: init$2.title,
                                                                      value: value,
                                                                      source: init$2.source
                                                                    }
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    }),
                                                  infoMode: state.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI.value !== defaultData.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI.value ? /* Restore */0 : /* Infos */({
                                                        _0: state.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI.source
                                                      }),
                                                  onRestore: (function (param) {
                                                      var init = state.indirectCosts;
                                                      var init$1 = state.indirectCosts.otherMedicalCost;
                                                      var init$2 = state.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI;
                                                      return updateLaiData({
                                                                  otherMedicalCost: {
                                                                    hospitalizationCost: init$1.hospitalizationCost,
                                                                    erAndPhysicianVisitCost: init$1.erAndPhysicianVisitCost,
                                                                    erAndPhysicianVisitPP1M: init$1.erAndPhysicianVisitPP1M,
                                                                    erAndPhysicianVisitPP3M: init$1.erAndPhysicianVisitPP3M,
                                                                    costPerAdministrationOfLAI: {
                                                                      title: init$2.title,
                                                                      value: defaultData.indirectCosts.otherMedicalCost.costPerAdministrationOfLAI.value,
                                                                      source: init$2.source
                                                                    }
                                                                  },
                                                                  indirectCostPatient: init.indirectCostPatient,
                                                                  indirectCostCaregiver: init.indirectCostCaregiver,
                                                                  incarceration: init.incarceration
                                                                });
                                                    })
                                                }))),
                                    undefined,
                                    undefined
                                  ]))),
                      undefined,
                      undefined
                    ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                      undefined,
                      Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Caml_option.some(null),
                      undefined,
                      undefined
                    ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(null),
                          undefined,
                          undefined
                        ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement("h3", undefined, "Indirect Cost Patient")),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-top-1 width-edit-form",
                              Caml_option.some(React.createElement("h3", undefined, "Patient-ORAL")),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-top-2 width-edit-form margin-edit-form",
                              Caml_option.some(React.createElement("h3", undefined, "Patient-LAI")),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-1 width-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostPatient.unemploymentRate.value
                                        },
                                        title: state.indirectCosts.indirectCostPatient.unemploymentRate.title,
                                        unit: "%",
                                        inputDisabled: inputDisabled,
                                        onValueUpdate: (function (value) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.unemploymentRate;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: {
                                                            title: init$2.title,
                                                            value: value,
                                                            source: init$2.source
                                                          },
                                                          absentHours: init$1.absentHours,
                                                          minimumDailyWage: init$1.minimumDailyWage,
                                                          workingDaysPerMonth: init$1.workingDaysPerMonth,
                                                          improvementRateWithLAIs: init$1.improvementRateWithLAIs,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          }),
                                        infoMode: state.indirectCosts.indirectCostPatient.unemploymentRate.value !== defaultData.indirectCosts.indirectCostPatient.unemploymentRate.value ? /* Restore */0 : /* Infos */({
                                              _0: state.indirectCosts.indirectCostPatient.unemploymentRate.source
                                            }),
                                        onRestore: (function (param) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.unemploymentRate;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: {
                                                            title: init$2.title,
                                                            value: defaultData.indirectCosts.indirectCostPatient.unemploymentRate.value,
                                                            source: init$2.source
                                                          },
                                                          absentHours: init$1.absentHours,
                                                          minimumDailyWage: init$1.minimumDailyWage,
                                                          workingDaysPerMonth: init$1.workingDaysPerMonth,
                                                          improvementRateWithLAIs: init$1.improvementRateWithLAIs,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostPatient.improvementRateWithLAIs.value
                                        },
                                        title: state.indirectCosts.indirectCostPatient.improvementRateWithLAIs.title,
                                        tooltipClassName: tooltipLeftClass,
                                        unit: "%",
                                        inputDisabled: inputDisabled,
                                        onValueUpdate: (function (value) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.improvementRateWithLAIs;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: init$1.unemploymentRate,
                                                          absentHours: init$1.absentHours,
                                                          minimumDailyWage: init$1.minimumDailyWage,
                                                          workingDaysPerMonth: init$1.workingDaysPerMonth,
                                                          improvementRateWithLAIs: {
                                                            title: init$2.title,
                                                            value: value,
                                                            source: init$2.source
                                                          },
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          }),
                                        infoMode: state.indirectCosts.indirectCostPatient.improvementRateWithLAIs.value !== defaultData.indirectCosts.indirectCostPatient.improvementRateWithLAIs.value ? /* Restore */0 : /* Infos */({
                                              _0: state.indirectCosts.indirectCostPatient.improvementRateWithLAIs.source
                                            }),
                                        onRestore: (function (param) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.improvementRateWithLAIs;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: init$1.unemploymentRate,
                                                          absentHours: init$1.absentHours,
                                                          minimumDailyWage: init$1.minimumDailyWage,
                                                          workingDaysPerMonth: init$1.workingDaysPerMonth,
                                                          improvementRateWithLAIs: {
                                                            title: init$2.title,
                                                            value: defaultData.indirectCosts.indirectCostPatient.improvementRateWithLAIs.value,
                                                            source: init$2.source
                                                          },
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-1 width-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostPatient.absentHours.value
                                        },
                                        title: state.indirectCosts.indirectCostPatient.absentHours.title,
                                        unit: "hr",
                                        inputDisabled: inputDisabled,
                                        onValueUpdate: (function (value) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.absentHours;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: init$1.unemploymentRate,
                                                          absentHours: {
                                                            title: init$2.title,
                                                            value: value,
                                                            source: init$2.source
                                                          },
                                                          minimumDailyWage: init$1.minimumDailyWage,
                                                          workingDaysPerMonth: init$1.workingDaysPerMonth,
                                                          improvementRateWithLAIs: init$1.improvementRateWithLAIs,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          }),
                                        infoMode: state.indirectCosts.indirectCostPatient.absentHours.value !== defaultData.indirectCosts.indirectCostPatient.absentHours.value ? /* Restore */0 : /* Infos */({
                                              _0: state.indirectCosts.indirectCostPatient.absentHours.source
                                            }),
                                        onRestore: (function (param) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.absentHours;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: init$1.unemploymentRate,
                                                          absentHours: {
                                                            title: init$2.title,
                                                            value: defaultData.indirectCosts.indirectCostPatient.absentHours.value,
                                                            source: init$2.source
                                                          },
                                                          minimumDailyWage: init$1.minimumDailyWage,
                                                          workingDaysPerMonth: init$1.workingDaysPerMonth,
                                                          improvementRateWithLAIs: init$1.improvementRateWithLAIs,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                              undefined,
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-1 width-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostPatient.minimumDailyWage.value
                                        },
                                        title: state.indirectCosts.indirectCostPatient.minimumDailyWage.title,
                                        unit: currency.code,
                                        inputDisabled: inputDisabled,
                                        onValueUpdate: (function (value) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.minimumDailyWage;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: init$1.unemploymentRate,
                                                          absentHours: init$1.absentHours,
                                                          minimumDailyWage: {
                                                            title: init$2.title,
                                                            value: value,
                                                            source: init$2.source
                                                          },
                                                          workingDaysPerMonth: init$1.workingDaysPerMonth,
                                                          improvementRateWithLAIs: init$1.improvementRateWithLAIs,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          }),
                                        infoMode: state.indirectCosts.indirectCostPatient.minimumDailyWage.value !== defaultData.indirectCosts.indirectCostPatient.minimumDailyWage.value ? /* Restore */0 : /* Infos */({
                                              _0: state.indirectCosts.indirectCostPatient.minimumDailyWage.source
                                            }),
                                        onRestore: (function (param) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.minimumDailyWage;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: init$1.unemploymentRate,
                                                          absentHours: init$1.absentHours,
                                                          minimumDailyWage: {
                                                            title: init$2.title,
                                                            value: defaultData.indirectCosts.indirectCostPatient.minimumDailyWage.value,
                                                            source: init$2.source
                                                          },
                                                          workingDaysPerMonth: init$1.workingDaysPerMonth,
                                                          improvementRateWithLAIs: init$1.improvementRateWithLAIs,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                              undefined,
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-1 width-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostPatient.workingDaysPerMonth.value
                                        },
                                        title: state.indirectCosts.indirectCostPatient.workingDaysPerMonth.title,
                                        unit: "days",
                                        inputDisabled: inputDisabled,
                                        digit: 0,
                                        onValueUpdate: (function (value) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.workingDaysPerMonth;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: init$1.unemploymentRate,
                                                          absentHours: init$1.absentHours,
                                                          minimumDailyWage: init$1.minimumDailyWage,
                                                          workingDaysPerMonth: {
                                                            title: init$2.title,
                                                            value: value | 0,
                                                            source: init$2.source
                                                          },
                                                          improvementRateWithLAIs: init$1.improvementRateWithLAIs,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          }),
                                        infoMode: state.indirectCosts.indirectCostPatient.workingDaysPerMonth.value !== defaultData.indirectCosts.indirectCostPatient.workingDaysPerMonth.value ? /* Restore */0 : /* Infos */({
                                              _0: state.indirectCosts.indirectCostPatient.workingDaysPerMonth.source
                                            }),
                                        onRestore: (function (param) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostPatient;
                                            var init$2 = state.indirectCosts.indirectCostPatient.workingDaysPerMonth;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: {
                                                          unemploymentRate: init$1.unemploymentRate,
                                                          absentHours: init$1.absentHours,
                                                          minimumDailyWage: init$1.minimumDailyWage,
                                                          workingDaysPerMonth: {
                                                            title: init$2.title,
                                                            value: defaultData.indirectCosts.indirectCostPatient.workingDaysPerMonth.value,
                                                            source: init$2.source
                                                          },
                                                          improvementRateWithLAIs: init$1.improvementRateWithLAIs,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        indirectCostCaregiver: init.indirectCostCaregiver,
                                                        incarceration: init.incarceration
                                                      });
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                              undefined,
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-bottom-1 total-form-1 width-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostPatient.oralCostPerMonth.value
                                        },
                                        title: state.indirectCosts.indirectCostPatient.oralCostPerMonth.title,
                                        unit: currency.code,
                                        inputDisabled: true,
                                        infoMode: /* Infos */{
                                          _0: state.indirectCosts.indirectCostPatient.oralCostPerMonth.source
                                        },
                                        onRestore: (function (param) {
                                            
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-bottom-2 total-form-2 width-edit-form margin-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostPatient.laiCostPerMonth.value
                                        },
                                        title: state.indirectCosts.indirectCostPatient.laiCostPerMonth.title,
                                        tooltipClassName: tooltipLeftClass,
                                        unit: currency.code,
                                        inputDisabled: true,
                                        infoMode: /* Infos */{
                                          _0: state.indirectCosts.indirectCostPatient.laiCostPerMonth.source
                                        },
                                        onRestore: (function (param) {
                                            
                                          })
                                      })),
                              undefined,
                              undefined
                            ]))), React.createElement(Grid, Curry.app(MaterialUI_Grid.Container.makeProps(undefined, undefined)(undefined, 0, undefined), [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          Caml_option.some(null),
                          undefined,
                          undefined
                        ]), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(12)),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              Caml_option.some(React.createElement("h3", undefined, "Indirect Cost Caregiver")),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-top-1 width-edit-form",
                              Caml_option.some(React.createElement("h3", undefined, "Caregivers-ORAL")),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-top-2 width-edit-form margin-edit-form",
                              Caml_option.some(React.createElement("h3", undefined, "Caregivers-LAI")),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-1 width-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostCaregiver.workHour.value
                                        },
                                        title: state.indirectCosts.indirectCostCaregiver.workHour.title,
                                        unit: "hr",
                                        inputDisabled: inputDisabled,
                                        digit: 0,
                                        onValueUpdate: (function (value) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostCaregiver;
                                            var init$2 = state.indirectCosts.indirectCostCaregiver.workHour;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: init.indirectCostPatient,
                                                        indirectCostCaregiver: {
                                                          workHour: {
                                                            title: init$2.title,
                                                            value: value | 0,
                                                            source: init$2.source
                                                          },
                                                          improvementInProductivity: init$1.improvementInProductivity,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        incarceration: init.incarceration
                                                      });
                                          }),
                                        infoMode: state.indirectCosts.indirectCostCaregiver.workHour.value !== defaultData.indirectCosts.indirectCostCaregiver.workHour.value ? /* Restore */0 : /* Infos */({
                                              _0: state.indirectCosts.indirectCostCaregiver.workHour.source
                                            }),
                                        onRestore: (function (param) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostCaregiver;
                                            var init$2 = state.indirectCosts.indirectCostCaregiver.workHour;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: init.indirectCostPatient,
                                                        indirectCostCaregiver: {
                                                          workHour: {
                                                            title: init$2.title,
                                                            value: defaultData.indirectCosts.indirectCostCaregiver.workHour.value,
                                                            source: init$2.source
                                                          },
                                                          improvementInProductivity: init$1.improvementInProductivity,
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        incarceration: init.incarceration
                                                      });
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-middle-2 width-edit-form margin-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostCaregiver.improvementInProductivity.value
                                        },
                                        title: state.indirectCosts.indirectCostCaregiver.improvementInProductivity.title,
                                        tooltipClassName: tooltipLeftClass,
                                        unit: "%",
                                        inputDisabled: inputDisabled,
                                        onValueUpdate: (function (value) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostCaregiver;
                                            var init$2 = state.indirectCosts.indirectCostCaregiver.improvementInProductivity;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: init.indirectCostPatient,
                                                        indirectCostCaregiver: {
                                                          workHour: init$1.workHour,
                                                          improvementInProductivity: {
                                                            title: init$2.title,
                                                            value: value,
                                                            source: init$2.source
                                                          },
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        incarceration: init.incarceration
                                                      });
                                          }),
                                        infoMode: state.indirectCosts.indirectCostCaregiver.improvementInProductivity.value !== defaultData.indirectCosts.indirectCostCaregiver.improvementInProductivity.value ? /* Restore */0 : /* Infos */({
                                              _0: state.indirectCosts.indirectCostCaregiver.improvementInProductivity.source
                                            }),
                                        onRestore: (function (param) {
                                            var init = state.indirectCosts;
                                            var init$1 = state.indirectCosts.indirectCostCaregiver;
                                            var init$2 = state.indirectCosts.indirectCostCaregiver.improvementInProductivity;
                                            return updateLaiData({
                                                        otherMedicalCost: init.otherMedicalCost,
                                                        indirectCostPatient: init.indirectCostPatient,
                                                        indirectCostCaregiver: {
                                                          workHour: init$1.workHour,
                                                          improvementInProductivity: {
                                                            title: init$2.title,
                                                            value: defaultData.indirectCosts.indirectCostCaregiver.improvementInProductivity.value,
                                                            source: init$2.source
                                                          },
                                                          oralCostPerMonth: init$1.oralCostPerMonth,
                                                          laiCostPerMonth: init$1.laiCostPerMonth
                                                        },
                                                        incarceration: init.incarceration
                                                      });
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-bottom-1 total-form-1 width-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostCaregiver.oralCostPerMonth.value
                                        },
                                        title: state.indirectCosts.indirectCostCaregiver.oralCostPerMonth.title,
                                        unit: currency.code,
                                        inputDisabled: true,
                                        infoMode: /* Infos */{
                                          _0: state.indirectCosts.indirectCostCaregiver.oralCostPerMonth.source
                                        },
                                        onRestore: (function (param) {
                                            
                                          })
                                      })),
                              undefined,
                              undefined
                            ])), React.createElement(Grid, Curry.app(MaterialUI_Grid.Item.makeProps(undefined, undefined)(undefined, undefined, undefined), [
                              undefined,
                              Caml_option.some(MaterialUIDataType.GridSize.size(6)),
                              undefined,
                              undefined,
                              undefined,
                              "bg-edit-form-bottom-2 total-form-2 width-edit-form margin-edit-form",
                              Caml_option.some(React.createElement(LAIInputForm$CTEM.make, {
                                        inputMode: {
                                          TAG: /* FloatInput */1,
                                          _0: state.indirectCosts.indirectCostCaregiver.laiCostPerMonth.value
                                        },
                                        title: state.indirectCosts.indirectCostCaregiver.laiCostPerMonth.title,
                                        tooltipClassName: tooltipLeftClass,
                                        unit: currency.code,
                                        inputDisabled: true,
                                        infoMode: /* Infos */{
                                          _0: state.indirectCosts.indirectCostCaregiver.laiCostPerMonth.source
                                        },
                                        onRestore: (function (param) {
                                            
                                          })
                                      })),
                              undefined,
                              undefined
                            ])))));
}

var make = LAIIndirectCosts;

export {
  make ,
  
}
/* react Not a pure module */
