// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import Modal from "@material-ui/core/Modal";

var setScale = (function() {
    setTimeout(function(){
      var scale = localStorage.getItem('scaleRatio');
      if (scale == null) {
        scale = 1;
      }
      var modals = document.getElementsByClassName("modal-dialog-content");
      if (modals) {
        for (var i = 0; i < modals.length; i++) {
          modals[i].style.transform = "translateY(calc(50vh - 50%)) scale("+ scale +")";
          modals[i].style.opacity = 1;
        }
      }
    }, 0);
  });

function ModalDialog(Props) {
  var _openOpt = Props.open;
  var classNameOpt = Props.className;
  var onHandleClose = Props.onHandleClose;
  var children = Props.children;
  var _open = _openOpt !== undefined ? _openOpt : true;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  Curry._1(setScale, undefined);
  return React.createElement(Modal, {
              children: React.createElement("div", {
                    className: "modal-dialog-content"
                  }, children),
              className: className,
              onClose: onHandleClose,
              open: _open
            });
}

var make = ModalDialog;

export {
  setScale ,
  make ,
  
}
/* react Not a pure module */
