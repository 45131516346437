// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/lib/es6/src/Json.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.bs.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Utils$CTEM from "./Utils.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function buildRequest(verbOpt, headers, body, param) {
  var verb = verbOpt !== undefined ? verbOpt : /* Get */0;
  if (body !== undefined) {
    return Fetch.RequestInit.make(verb, Caml_option.some(headers), Caml_option.some(JSON.stringify(Caml_option.valFromOption(body))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  } else {
    return Fetch.RequestInit.make(verb, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  }
}

function buildHeader(param) {
  var token = localStorage.getItem("token");
  if (token !== null) {
    return {
            "Content-Type": "application/json",
            Authorization: "Token " + token
          };
  } else {
    return {
            "Content-Type": "application/json"
          };
  }
}

function request(requestInit, url, decoder, param) {
  return fetch(url, requestInit).then(function (prim) {
                  return prim.json();
                }).then(function (json) {
                if (decoder === undefined) {
                  return Promise.resolve(undefined);
                }
                var response = Curry._1(decoder, json);
                return Promise.resolve(Caml_option.some(response));
              }).catch(function (err) {
              console.log("Request failure -> " + url, err);
              return Promise.resolve(undefined);
            });
}

function requestJsonResponseToAction(requestInit, url, successAction, failAction) {
  return fetch(url, requestInit).then(function (response) {
                var status = response.status;
                if ((status / 100 | 0) === 2) {
                  return response.json().then(function (json) {
                                return Promise.resolve(Curry._1(successAction, json));
                              }).catch(function (_err) {
                              return Promise.resolve(Curry._1(successAction, null));
                            });
                } else {
                  return response.json().then(function (json) {
                              return Promise.resolve(Curry._1(failAction, json));
                            });
                }
              }).catch(function (e) {
              console.log("catch", e);
              return Promise.resolve(Curry._1(failAction, null));
            });
}

function getResponseMsgFromJson(json) {
  var match = Js_json.classify(json);
  if (typeof match === "number" && match >= 2) {
    return Utils$CTEM.defaultErrorMessage;
  }
  var jsonString = Json.stringify(json);
  var re = new RegExp("[\\[\\]\\{\\}\"]", "g");
  var splitMsg = jsonString.replace(re, "").split(":");
  return Caml_array.get(splitMsg, splitMsg.length - 1 | 0);
}

export {
  buildRequest ,
  buildHeader ,
  request ,
  requestJsonResponseToAction ,
  getResponseMsgFromJson ,
  
}
/* Utils-CTEM Not a pure module */
