// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function initValue_fetchData(param) {
  
}

function initValue_updateData(param) {
  
}

var initValue = {
  value: /* NotLoaded */0,
  fetchData: initValue_fetchData,
  updateData: initValue_updateData
};

var context = React.createContext(initValue);

function useSavedWorksResults(param) {
  return React.useContext(context);
}

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var Provider = {
  make: make,
  makeProps: makeProps
};

export {
  initValue ,
  context ,
  useSavedWorksResults ,
  Provider ,
  
}
/* context Not a pure module */
