// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App$CTEM from "./App.bs.js";
import * as ReactDom from "react-dom";

((require("/src/index.scss")));

((console.log(`version: ${BUILD.VERSION}`)));

var root = document.querySelector("#root");

if (!(root == null)) {
  ReactDom.render(React.createElement(App$CTEM.make, {}), root);
}

export {
  
}
/*  Not a pure module */
