// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function makeProps(prim, prim$1, prim$2, prim$3, prim$4, prim$5, prim$6, prim$7, prim$8, prim$9, prim$10, prim$11, prim$12, prim$13, prim$14, prim$15, prim$16, prim$17, prim$18, prim$19, prim$20, prim$21, prim$22, prim$23, prim$24, prim$25, prim$26, prim$27, prim$28, prim$29, prim$30) {
  var tmp = {};
  if (prim !== undefined) {
    tmp.id = Caml_option.valFromOption(prim);
  }
  if (prim$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(prim$1);
  }
  if (prim$2 !== undefined) {
    tmp.label = Caml_option.valFromOption(prim$2);
  }
  if (prim$3 !== undefined) {
    tmp.name = Caml_option.valFromOption(prim$3);
  }
  if (prim$4 !== undefined) {
    tmp.helperText = Caml_option.valFromOption(prim$4);
  }
  if (prim$5 !== undefined) {
    tmp.variant = Caml_option.valFromOption(prim$5);
  }
  if (prim$6 !== undefined) {
    tmp.margin = Caml_option.valFromOption(prim$6);
  }
  if (prim$7 !== undefined) {
    tmp.required = Caml_option.valFromOption(prim$7);
  }
  if (prim$8 !== undefined) {
    tmp.fullWidth = Caml_option.valFromOption(prim$8);
  }
  if (prim$9 !== undefined) {
    tmp.autoComplete = Caml_option.valFromOption(prim$9);
  }
  if (prim$10 !== undefined) {
    tmp.autoFocus = Caml_option.valFromOption(prim$10);
  }
  if (prim$11 !== undefined) {
    tmp.defaultValue = Caml_option.valFromOption(prim$11);
  }
  if (prim$12 !== undefined) {
    tmp.value = Caml_option.valFromOption(prim$12);
  }
  if (prim$13 !== undefined) {
    tmp.onChange = Caml_option.valFromOption(prim$13);
  }
  if (prim$14 !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(prim$14);
  }
  if (prim$15 !== undefined) {
    tmp.type = Caml_option.valFromOption(prim$15);
  }
  if (prim$16 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim$16);
  }
  if (prim$17 !== undefined) {
    tmp.classes = Caml_option.valFromOption(prim$17);
  }
  if (prim$18 !== undefined) {
    tmp.color = Caml_option.valFromOption(prim$18);
  }
  if (prim$19 !== undefined) {
    tmp.error = Caml_option.valFromOption(prim$19);
  }
  if (prim$20 !== undefined) {
    tmp.FormHelperTextProps = Caml_option.valFromOption(prim$20);
  }
  if (prim$21 !== undefined) {
    tmp.InputLabelProps = Caml_option.valFromOption(prim$21);
  }
  if (prim$22 !== undefined) {
    tmp.InputProps = Caml_option.valFromOption(prim$22);
  }
  if (prim$23 !== undefined) {
    tmp.inputProps = Caml_option.valFromOption(prim$23);
  }
  if (prim$24 !== undefined) {
    tmp.inputRef = Caml_option.valFromOption(prim$24);
  }
  if (prim$25 !== undefined) {
    tmp.multiline = Caml_option.valFromOption(prim$25);
  }
  if (prim$26 !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(prim$26);
  }
  if (prim$27 !== undefined) {
    tmp.rows = Caml_option.valFromOption(prim$27);
  }
  if (prim$28 !== undefined) {
    tmp.rowsMax = Caml_option.valFromOption(prim$28);
  }
  if (prim$29 !== undefined) {
    tmp.size = Caml_option.valFromOption(prim$29);
  }
  return tmp;
}

var Variant;

export {
  Variant ,
  makeProps ,
  
}
/* No side effect */
