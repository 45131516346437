// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MaterialUIDataType from "@divertise-asia/bs-mui/lib/es6/src/MaterialUIDataType.bs.js";
import * as MaterialUI_TextField from "@divertise-asia/bs-mui/lib/es6/src/Components/MaterialUI_TextField.bs.js";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function CtemAutoComplete(Props) {
  var labelOpt = Props.label;
  var listItem = Props.listItem;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var label = labelOpt !== undefined ? labelOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var renderInput = function (params) {
    return React.createElement(TextField, MaterialUI_TextField.makeProps(undefined, undefined, label, undefined, undefined, Caml_option.some(MaterialUIDataType.Variant.outlined), "normal", undefined, params.fullWidth, undefined, undefined, undefined, undefined, undefined, undefined, params.disabled, undefined, undefined, undefined, undefined, Caml_option.some(params["InputLabelProps"]), Caml_option.some(params["InputProps"]), Caml_option.some(params.inputProps), undefined, undefined, undefined, undefined, undefined, params.size, undefined));
  };
  return React.createElement(Autocomplete, {
              disabled: disabled,
              freeSolo: true,
              onChange: (function (_evt, maybeData, _reason) {
                  if (maybeData == null) {
                    console.log("Select Nothing");
                    return ;
                  } else {
                    return Curry._1(onChange, maybeData);
                  }
                }),
              options: $$Array.of_list(listItem),
              renderInput: renderInput
            });
}

var make = CtemAutoComplete;

export {
  make ,
  
}
/* react Not a pure module */
